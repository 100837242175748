import styles from './CreateProgressBar.module.css';
import RoundStep from "./RoundStep";
import StepLine from "./StepLine";
import {useTranslation} from "react-i18next";
import React from "react";

const CreateProgressBar = (props) => {

    const {t, i18n} = useTranslation();
    const step = props.step;

    const getStateType = (index) => {
        if (step === 3)
            return 'done';

        if (step === index)
            return 'active';
        if (step < index)
            return '';
        if (step > index)
            return 'done';
    }

    const getStateProgress = (index) => {
        if (step === index)
            return 50;
        if (step < index)
            return 0;
        if (step > index)
            return 100;
    }

    return <div className={styles.container}>


        {/*<StepLine width={35} className={styles.stepLine}/>*/}
        <div className={styles.stepsContainer}>
            <RoundStep text={1} state={getStateType(1)} title={t('create progress title 0')}/>
            <StepLine width={getStateProgress(1)} />
            <RoundStep text={2} state={getStateType(2)} title={t('create progress title 1')}/>
            <StepLine width={getStateProgress(2)} />
            <RoundStep text={3} state={getStateType(3)} title={t('create progress title 2')}/>
        </div>


    </div>;
}

export default CreateProgressBar;
