import styles from './ProfileButton.module.css';
import {ClipLoader} from "react-spinners";
import {NavLink} from "react-router-dom";
import userStore from "../../store/userStore";
import loadingStore from "../../store/loadingStore";
import {signInWithGoogle} from "../../util/firebase-auth";
import {useTranslation} from "react-i18next";
import GradientButton from "../UI/designs/GradientButton";

const ProfileButton = (props) => {

    const {t, i18n} = useTranslation();

    const handleSignInWithGoogle = () => {
        signInWithGoogle();
    }

    const getNavLinkImageClass = (isActive) => {
        return isActive ? styles.menuItemProfileActive : styles.menuItemProfile;
    }

    let content;
    if (loadingStore.value.user) {
        content = <div className={styles.container}>
            <div className={styles.menuItemProfileLoading}>
                <ClipLoader color="#7155F6" loading={true} size={18}
                            aria-label="Loading Spinner"
                            data-testid="loader"/>
            </div>
        </div>;
    } else {
        if (userStore.value.user === null) {
            // Return SignIn
            content = <div className={styles.containerSignIn}>
                <GradientButton className={styles.signInButton} onClick={handleSignInWithGoogle} text={t('nav log_in')}/>
            </div>
        } else {
            // Return Profile Button
            content = <div className={styles.container}>
                <NavLink
                    to={'profile'}
                    className={({isActive}) => getNavLinkImageClass(isActive)}>
                    <img className={styles.menuProfileImage} src={userStore.value.user.photoURL}
                         alt={t('nav log_in alt')}
                         referrerPolicy="no-referrer"
                    />
                </NavLink>
            </div>
        }
    }


    return content;
}
export default ProfileButton;
