import {app} from "./firebase";
import {getAuth, signInAnonymously, signInWithPopup, GoogleAuthProvider} from "firebase/auth";
import userStore from '../store/userStore';
import loadingStore from '../store/loadingStore';
import {fetchUser} from "./backend-utils";
import {setUser} from "./firebase-analytics";


const auth = getAuth(app);

auth.onAuthStateChanged(async (firebaseUser) => {
    await refreshAuthToken();

    if (!firebaseUser) {
        await signInAnonymously(auth);
        return;
    }

    if (!firebaseUser.isAnonymous) {
        // Perform Login With BackEnd
        await fetchUser(firebaseUser);

        // Set User For Analytics
        setUser(firebaseUser.uid);
    }

    loadingStore.value = {...loadingStore.value, user: false};
});

const signInWithGoogle = async () => {

    if (auth.currentUser && !auth.currentUser.isAnonymous) {
        await refreshAuthToken();
    } else {
        signInWithPopup(auth, new GoogleAuthProvider()).then(async (firebaseUser) => {
            // Set User For Analytics
            await fetchUser(firebaseUser.user);
            setUser(firebaseUser.user.uid);
        })
    }
}
/*

const toggleLoadingUser = (isLoadingUser) => {
    uiStore.value = {...uiStore.value, isLoadingUser};
}

const toggleLoadingUserGifts = (isLoadingUserGifts) => {
    uiStore.value = {...uiStore.value, isLoadingUserGifts};
}

const toggleIsLoadingGallery = (isLoadingImageGallery) => {
    uiStore.value = {...uiStore.value, isLoadingImageGallery};
}

const toggleIsLoadingBlogs = (isLoadingBlog) => {
    uiStore.value = {...uiStore.value, isLoadingBlog};
}

const fetchUserFromBackend = async (firebaseUser) => {
    const userResponse = await POST("users/get", {
        uid: firebaseUser.uid,
        email: firebaseUser.email,
        displayName: firebaseUser.displayName,
        photoURL: firebaseUser.photoURL,
    });

    // Update User
    if (userResponse.status === 200) {
        console.log('magicTextReward', userResponse.user);
        userStore.value.user = userResponse.user;
        userStore.value.user.photoURL = firebaseUser.photoURL;
    }
}

const fetchGallery = async () => {
    const galleryRes = await GET('users/imageGallery');
    if (galleryRes.status === 200) {
        userStore.value.gallery = galleryRes.imagesArray;
    }
}

const fetchAllBlogs = async () => {
    const blogsRes = await GET('users/blogs');
    if (blogsRes.status === 200) {
        const sortedBlogs = blogsRes.blogsArr.sort((a,b) => {return new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime()}).reverse();
        userStore.value.blogs = sortedBlogs;
    }
}


const fetchUserGifts = async () => {
    if (userStore.value.user !== null) {
        const allGiftsRes = await GET("gifts/findAll");
        if (allGiftsRes.status === 200)
            userStore.value.user['gifts'] = allGiftsRes.gifts
        else
            userStore.value.user['gifts'] = []
    }
}
*/

const refreshAuthToken = async () => {
    const token = await auth.currentUser?.getIdToken(false);
    if (token && userStore.value.token !== token)
        userStore.value = {
            ...userStore.value,
            token
        };
    return token;
}


const logOut = async () => {
    userStore.value = {...userStore.value, user: null,}
    await auth.signOut();
    refreshAuthToken();
};

export {refreshAuthToken, signInWithGoogle, logOut};
