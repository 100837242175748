import styles from './AboutUs.module.css';
import React, {useEffect} from "react";
import aboutUsImg from '../../assets/images/about_us.png'
import {clickEmail} from '../../util/utils';
import {useTranslation} from 'react-i18next';
import {sendEvent} from "../../util/firebase-analytics";

const AboutUs = (props) => {

    const {t, i18n} = useTranslation();


    useEffect(()=>{
        // Page Open Analytics
        sendEvent('screenview', {screen_name: 'about'})
    },[])

    return (
        <div className={styles.mainContainer}>

            <img className={styles.image} src={aboutUsImg} alt={t('about us title')}/>

            <div className={styles.textContainer}>
                <h1 className={styles.title}>{t('about us title')}</h1>
                <p className={styles.text}>{t('about us content')}</p>
                <span className={styles.contact}>{t('about us contact us')}</span>
                <button className={styles.buttonContactUs} onClick={clickEmail}>help.txt.gift@gmail.com</button>
            </div>
        </div>
    );
}

export default AboutUs;
