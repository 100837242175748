import styles from './MagicMessageGenerate.module.css';
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {ClipLoader} from "react-spinners";
import {signInWithGoogle} from "../../../util/firebase-auth";
import {magicTextType} from "../../../util/MagicTemplates";
import userStore from '../../../store/userStore';
import loadingStore from '../../../store/loadingStore';
import GradientButton from "../../UI/designs/GradientButton";
import Selector from "awesome-selector";
import {selectorStyle} from "../../../util/utils";

let MagicTypesTranslated = null;
let slelectorList = [];
const MagicMessageGenerate = (props) => {

    const {t, i18n} = useTranslation();
    const [magicBody, setMagicBody] = useState({});
    const [magicType, setMagicType] = useState(null);


    // Create Translated List If Not Exists
    if (MagicTypesTranslated === null) {
        MagicTypesTranslated = magicTextType();
        for (const item of MagicTypesTranslated) {
            item['name'] = t(item['translationKey']);
            if (MagicTypesTranslated['extraInput']) {
                for (const extra of MagicTypesTranslated['extraInput']) {
                    extra['name'] = t(item['translationKey']);
                    extra['placeHolder'] = t(item['translationPlaceHolder']);
                }
            }
        }
    }


    const handleChangeType = async () => {

        // Because State Are reloaded a lot, this will make sure to the rest of modals
        for( const selector of slelectorList ) {
            await selector.hide();
        }

        slelectorList.push(
            new Selector({
            theme: 'light',
            title: t('magic message create magic click to start'),
            options: MagicTypesTranslated,
            style: {
                ...selectorStyle,
                shadow: [
                    ['background-color', 'rgba(0,0,0, 0.0'],
                    ['padding', '1rem'],
                    ['margin', '0'],
                ]
            },
            onSelect: (id, name) => {
                const selectedItem = MagicTypesTranslated.find((item) => item.id === id);
                handleOnTypeSelected(selectedItem);
            },
        })
        );
    }

    async function simulateMouseClick(el) {
        let opts = {view: window, bubbles: true, cancelable: true, buttons: 1};
        el.dispatchEvent(new MouseEvent("mousedown", opts));
        await new Promise(r => setTimeout(r, 50));
        el.dispatchEvent(new MouseEvent("mouseup", opts));
        el.dispatchEvent(new MouseEvent("click", opts));
    }

    const handleOnTypeSelected = (item) => {

        // Set MagicType
        setMagicType(item);

        // Restart MessageGeneratedBody
        const newBody = {
            type: item.id,
            to: magicBody.to,
            relation: magicBody.relation,
            extra_details: magicBody.extra_details,
        }
        if (item.extraInput)
            for (const extraInputs of item.extraInput)
                newBody[extraInputs.id] = '';
        setMagicBody(newBody);

    };

    const handleInputChange = (label, value) => {
        setMagicBody({
            ...magicBody,
            [label]: value,
        });
    }

    const handleGenerateClick = async (event) => {
        event.preventDefault();
        props.onGenerated(magicBody);
    }


    if (loadingStore.value.user) {
        return <div className={styles.container}>
            {/*LOADING*/}
            <ClipLoader color="#7155F6" loading={true} size={38} aria-label="Loading Spinner" data-testid="loader"/>
        </div>
    }


    if (userStore.value.user === null) {
        return <div className={styles.container}>
            {/*SIGN IN BUTTON*/}
            <div className={styles.signInContainer}>
                <div className={styles.signInName}>{t('magic message not logged in name')}</div>
                <div className={styles.signInTitle}>{t('magic message not logged in title')}</div>
                <GradientButton className={styles.signInButton} onClick={() => {
                    signInWithGoogle()
                }} text={t('magic message not logged in button')}/>
                <div className={styles.signInText}>{t('magic message not logged in small text')}</div>
            </div>
        </div>
    }


    if (!loadingStore.value.user /*&& userStore.value.user !== null */ && magicType === null) {
        // On First Show Of Generator, Skip The Select Type
        handleChangeType();
        return <div className={styles.container}>
            <div className={styles.signInContainer}>
                <label className={styles.title}>{t('magic message create magic title')}</label>
                <div className={styles.selectButton}
                     onClick={handleChangeType}>{t('magic message create magic click to start')}</div>
            </div>
        </div>
    }


    return <div className={styles.container}>
        <div className={styles.afterSignInContainer}>

            <label className={styles.title}>{t('magic message create magic title')}</label>

            {/*NO TYPE IS SELECTED*/}
            {magicType === null &&
                <>
                    <div className={styles.selectButton}
                         onClick={handleChangeType}>{t('magic message create magic click to start')}</div>
                </>
            }

            {/*TYPE IS SELECTED*/}
            {magicType !== null &&
                <>
                    <div className={styles.selectButton} onClick={handleChangeType}>{magicType.name}</div>
                    <form className={styles.form} onSubmit={handleGenerateClick}>
                        <label className={styles.label}>{t('magic message create magic to title')}</label>

                        {/*TO WHOM*/}
                        <input className={styles.input} placeholder={t('magic message create magic to place holder')}
                               onChange={(element) => handleInputChange('to', element.target.value)}
                        />

                        {/*RELATION TYPE*/}
                        <label className={styles.label}>{t('magic message create magic relation title')}</label>
                        <input className={styles.input}
                               placeholder={t('magic message create magic relation place holder')}
                               onChange={(element) => handleInputChange('relation', element.target.value)}
                        />

                        {/*EXTRA INPUT*/}
                        {magicType && magicType.extraInput && magicType.extraInput.length > 0 &&
                            <>
                                {magicType.extraInput.map((item) => {
                                    return <div key={item.id} className={styles.extraInputField}>
                                        <label className={styles.label}>{t(item.translationKey)}</label>
                                        <input className={styles.input}
                                               onChange={(element) => handleInputChange(item.id, element.target.value)}
                                               type={item.type} placeholder={t(item.translationPlaceHolder)}/>
                                    </div>
                                })}
                            </>
                        }

                        {/*EXTRA DETAILS (OPTIONAL) */}
                        <label className={styles.label}>{t('magic message create magic extra title')}</label>
                        <input className={styles.input} placeholder={t('magic message create magic extra place holder')}
                               onChange={(element) => handleInputChange('extra_details', element.target.value)}
                        />


                        {/*SUBMIT BUTTON*/}
                        <div className={styles.buttonsContainer}>
                            <button type="submit"
                                    className={styles.buttonNext}>{t('magic message create magic generate button')}</button>
                            <div
                                className={styles.textRemaining}>{t('magic message create magic left')}<b> {userStore.value.user.magicTexts}</b>
                            </div>
                        </div>
                    </form>
                </>
            }
        </div>
    </div>
}

export default MagicMessageGenerate;
