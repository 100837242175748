import {AnimatePresence, motion} from "framer-motion";
import styles from "./GiftStart.module.css";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

let clickCount = 0;
const GiftStart = (props) => {

    const {t, i18n} = useTranslation();
    const [showTapToOpen, setShowTapToOpen] = useState(false);
    const [canOpenGift, setCanOpenGift] = useState(false);



    useEffect(() => {
        setTimeout(() => {
            setShowTapToOpen(true);
        }, 2200);
        setTimeout(() => {
            setCanOpenGift(true);
        }, 2000);
    }, [])

    function clickHandle() {
        if (clickCount > 0) return;
        clickCount++;
        props.onGiftOpened();
    }

    return <div className={styles.container}>
        <AnimatePresence>
            {showTapToOpen &&
                <motion.div
                    className={styles.tapToOpen}
                    onClick={clickHandle}
                    transition={{duration: 1, type: "spring"}}
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    exit={{opacity: 0}}
                >
                    {t('gift tap to open')}
                </motion.div>
            }
        </AnimatePresence>

        <div
            onClick={clickHandle}
            className={styles.clickUI}/>
    </div>;


}

export default GiftStart;
