import styles from './UserSettings.module.css';
import UserDetails from "./user_settings/UserDetails";
import React, {useEffect, useState} from "react";
import MyGifts from "./user_settings/MyGifts";

const UserSettings = (props) => {

    return <div className={styles.container}>

        <div className={styles.contentContainer}>

            <UserDetails/>
            <MyGifts/>

        </div>


    </div>;
}

export default UserSettings;
