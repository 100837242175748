import styles from './HomeHeader.module.css';
import home_image_0 from '../../assets/images/home_0.png'
import React from "react";
import GradientButton from "../UI/designs/GradientButton";
import {useTranslation} from "react-i18next";

const HomeHeader = (props) => {
    const {t, i18n} = useTranslation();

    return <div className={styles.container}>
        <div className={styles.segmentInner}>
            <h1 className={styles.title}>{t('home segment0 title')}</h1>
            <h2 className={styles.text}>{t('home segment0 description')}</h2>
            <div className={styles.startButtonContainer}>
                <GradientButton onClick={props.onButtonClick} className={styles.startButton} text={t('home create gift')}/>
            </div>
        </div>
        <img className={styles.segmentImage} src={home_image_0} alt="gift box"/>
    </div>;
}

export default HomeHeader;
