import lottie_error_404 from "../../assets/404.json";
import styles from './ErrorPage.module.css';
import {Player} from "@lottiefiles/react-lottie-player";
import React from "react";
import {useTranslation} from "react-i18next";
import {NavLink} from "react-router-dom";

const ErrorPage = (props) => {

    const {t, i18n} = useTranslation();

    return <div className={styles.container}>

        <Player
            src={lottie_error_404}
            className={styles.lottie}
            loop
            autoplay
        />

        <div className={styles.textContainer}>
            <div className={styles.textTitle}>
                {t('error 404 title text')}
            </div>
            <div className={styles.textError}>
                {t('error 404 error text')}
            </div>
            <div className={styles.textDesc}>
                {t('error 404 error desc')}
            </div>
            <NavLink className={styles.buttonCreate} to="/create">
                {t('error 404 error button create')}
            </NavLink>
            <NavLink className={styles.buttonHome} to="/">
                {t('error 404 error button home')}
            </NavLink>

        </div>

    </div>
}

export default ErrorPage;
