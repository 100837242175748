import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import userStore from "../../store/userStore";
import CreateProgressBar from "../../components/create/progress/CreateProgressBar";
import styles from ".//CreatePage.module.css";
import {useTranslation} from "react-i18next";
import MessageForm from "../../components/create/steps/MessageForm";
import Card from "../../components/UI/Card";
import ImageForm from "../../components/create/steps/ImageForm";
import Modal from "../../components/UI/Modal";
import {Player} from "@lottiefiles/react-lottie-player";
import lottie_feedback_loading from "../../assets/feedback_loading.json";
import BackNextButtons from "../../components/create/BackNextButtons";
import MagicImages from "../../components/create/templates/MagicImages";
import GiftForm from "../../components/create/steps/GiftForm";
import ShareGift from "../../components/create/steps/ShareGift";
import {setOrCreateGift} from "../../util/backend-utils";
import {fetchUserGifts} from "../../util/backend-utils";
import {ClipLoader} from "react-spinners";
import {sendEvent} from "../../util/firebase-analytics";
import ErrorMessage from "../../components/create/ErrorMessage";


const CreatePage = () => {

    useEffect(()=>{
        // Page Open Analytics
        sendEvent('screenview', {screen_name: 'create'})
        sendEvent('create_start', {start_count: 1})
    },[])

    const [showAd, setShowAd] = useState(false);
    const [step, setStep] = useState(1);
    const [gift, setGift] = useState(defaultData);
    const navigate = useNavigate();
    const {t, i18n} = useTranslation();
    const [showLoadingGiftFromDB, setShowLoadingGiftFromDB] = useState(false);

    const { giftId } = useParams();
    useEffect(() => {
        if (giftId !== undefined) {

            setShowLoadingGiftFromDB(true);

            const hasUserGiftsArray = userStore.value.user?.gifts?.length > 0;
            if (hasUserGiftsArray) {
                setEditMode(giftId);
            } else {
                fetchUserGifts().then(()=>{
                    setEditMode(giftId);
                });
            }
        }
    }, [giftId])

    const setEditMode = (giftID) => {
        const giftFromDB = userStore.value.user?.gifts?.find((item) => item.id === giftId);
        if (giftFromDB) {
            setGift({
                ...giftFromDB,
                image: giftFromDB.image ? giftFromDB.image : '',
                displayImage: giftFromDB.displayImage ? giftFromDB.displayImage : '',
                imageType: giftFromDB.displayImage ? 'url' : '',
                youtube: giftFromDB.youtube ? giftFromDB.youtube : '',
            });
        } else {
            navigate('/create')
        }

        setShowLoadingGiftFromDB(false);
    }

    // If Gift Param Found Its Edit Mode
    // const giftIdParams = useParams().giftId;
    // if (giftIdParams) {
    //     const isLoadingUserGifts = loadingStore.value.userGifts;
    //     const hasUserGiftsArray = userStore.value.user?.gift !== null;
    //     if (gift.id === '' && !isLoadingUserGifts && hasUserGiftsArray) {
    //         const giftFromDB = userStore.value.user?.gifts?.find((item) => item.id === giftIdParams);
    //         if (giftFromDB)
    //             setGift({
    //                 ...giftFromDB,
    //                 image: giftFromDB.image,
    //                 displayImage: giftFromDB.displayImage,
    //                 imageType: 'url',
    //             });
    //         else
    //             navigate('/create');
    //     }
    // }

    const handleNextClick = async () => {
        if (step === 1)
        {
            if (gift.message.trim() === '') {
                updateGift('error', `🚨️  ${t('create back create message error')}  🚨`)
            } else {
                setStep(2);
            }
        }
        if (step === 2) {
            updateGift('isLoading', true);

            const form_data = new FormData();
            form_data.append('message', gift.message);
            form_data.append('typeName', gift.typeName);
            if (gift.image) form_data.append('file', gift.image);
            if (gift.youtube) form_data.append('youtube', gift.youtube);
            if (gift.id) form_data.append('id', gift.id);

            console.log('gift', gift);
            const url = await setOrCreateGift(form_data);
            if (url && url.length > 0) {
                updateGift('shareUrl', url);
                setStep(3)
            }

            updateGift('isLoading', false);
        }

        document.documentElement.scrollTo(0, 0);
    }
    const handleBackClick = () => {
        if (step >= 2)
            setStep(step-1)
    }


    const updateGift = (key, value) => {
        setGift(preVal => {
            if (key === 'youtube')
            {
                sendEvent('create_start', {video_media: 1})
                return {
                    ...preVal,
                    image: '',
                    displayImage: '',
                    imageType: '',
                    [key]: value
                }
            }

            if (key === 'image')
            {
                sendEvent('create_start', {image_media: 1})
                return {
                    ...preVal,
                    youtube: '',
                    image: value.image,
                    displayImage: value.displayImage,
                    imageType: value.imageType,
                }
            }

            return {...preVal, [key]: value}
        });

        // Remove Error If Message Been Added
        if (key === 'message' && gift.error && gift.error !=='' && value && value.trim() !== '') {
            updateGift('error', '');
        }

    }


    return <div className={styles.container}>
        <CreateProgressBar step={step}/>

        {(gift.error && gift.error !== '') && <ErrorMessage text={gift.error}/>}

        <Card className={styles.card}>
            {step === 1 &&
                <>
                    <MessageForm onUpdate={updateGift} gift={gift}/>
                    <ImageForm onUpdate={updateGift} gift={gift}/>
                    <BackNextButtons showNext={true} showBack={false} onNextClick={handleNextClick} onBackClick={handleBackClick} />
                    {/*IMAGES TEMPLATE*/}
                    <MagicImages onImageSelected={(item) => {
                        updateGift('image', {image: item.image, displayImage: item.displayImage, imageType: 'url',});
                        document.documentElement.scrollTo({top: 400, left: 0, behavior: 'smooth'});
                    }}/>
                </>}
            {step === 2 &&
                <>
                    <GiftForm
                        typeName={gift.typeName}
                        onUpdate={(giftName)=>{
                        updateGift('typeName', giftName);
                    }}/>
                    <BackNextButtons showNext={true} showBack={true} onNextClick={handleNextClick} onBackClick={handleBackClick} />
                </>}

            {step === 3 &&
                <ShareGift
                    url={gift.shareUrl}
                    onCreateNewClick={() => {
                        setStep(1)
                        setGift(defaultData)
                        navigate('/create')
                    }}
                />}
        </Card>




        {/*LOADING*/}
        <Modal isShowing={gift.isLoading} onBackClick={() => {
        }}>
            <div style={{width: '20rem'}}>
                <Player
                    src={lottie_feedback_loading}
                    className="player"
                    loop
                    autoplay
                />
            </div>
        </Modal>


        {/*LOADING GIFT FROM DB (FOUND PARAM ID)*/}
        <Modal isShowing={showLoadingGiftFromDB} onBackClick={() => {
        }}>
            <div style={{width: '10rem', height: '3rem', display: 'flex', justifyContent: 'center', alignItems:'center'}}>
                <ClipLoader color="#7155F6" loading={true} size={25}
                            aria-label="Loading Spinner"
                            data-testid="loader"/>
            </div>
        </Modal>

    </div>;

}

// Default Gift For Create Page.
const defaultData = {
    id: '',
    message: '',
    image: '',          // Storage path
    displayImage: '',   // URL That show the image
    imageType: '',      // file, url
    youtube: '',
    typeName: 'circle_box_blue',
    error: null,
    shareUrl: 'https://txt.gift/g/Mam5qlF0tBJvurypSbRI',
    isLoading: null,
};

export default CreatePage;
