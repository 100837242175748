import i18n from 'i18next';
import {Languages} from '../i18n';

export function clickEmail () {
    const subject = encodeURIComponent('Support Request');
    const body = encodeURIComponent('Hello Support Team,\n\nI need assistance with the following:\n\n');
    const emailAddress = 'help.txt.gift@gmail.com'; // Replace with your support email address
    const mailtoLink = `mailto:${emailAddress}?subject=${subject}&body=${body}`;
    window.location.href = mailtoLink;
}

const LANG_STORAGE_KEY = 'selectedLanguage';

export const saveSelectedLanguage = (lang) => {
    localStorage.setItem(LANG_STORAGE_KEY, lang);
};

export const loadSelectedLanguage = () => {
    return localStorage.getItem(LANG_STORAGE_KEY);
};

export const initLanguage = async () => {
    let langToUse = loadSelectedLanguage();

    if (langToUse) {
        i18n.changeLanguage(langToUse);
    }
    else {
        // Try with country code
        try {
            let res = await (await fetch('https://ipapi.co/json')).json();
            langToUse = res.languages[0] + res.languages[1];
        } catch (e) {
            langToUse = 'en';
        }

        if (Languages.indexOf(langToUse) !== -1) {
            i18n.changeLanguage(langToUse);
            saveSelectedLanguage(langToUse);
        }
    }

};



export const validateYouTubeUrl = (url) => {
    if (url && url.trim() !== '') {
        var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
        var match = url.match(regExp);
        return (match && match[2].length == 11);
    }
    return false;
}

export const selectorStyle = {
    window: [
        ['left', '0 !important'],
        ['padding', '1rem'],
        ['margin', '0'],
    ],
    selected: [
        ['background-color', '#FFF'],
        // ['color', '#282'],
        ['padding', '20px'],
        ['margin', '0'],
    ],
    shadow: [
        ['background-color', 'rgba(0,0,0, 0.5'],
        ['padding', '1rem'],
        ['margin', '0'],
    ],
    title: [
        ['font-weight', '600'],
        ['font-family', 'Poppins, sans-serif'],
    ],
    optionButton: [
        ['height', '4rem'],
        ['font-weight', '400'],
        ['font-family', 'Poppins, sans-serif'],
    ],
};




export const getEmojiBasedOnLength = (charCount) => {
    let result = '😴';
    if (charCount === 0) result = '😴';
    else if (charCount < 10) result = '😪';
    else if (charCount < 20) result = '🥱';
    else if (charCount < 30) result = '😶';
    else if (charCount < 40) result = '🫤';
    else if (charCount < 50) result = '😐';
    else if (charCount < 60) result = '😯';
    else if (charCount < 70) result = '🧐';
    else if (charCount < 80) result = '🙂';
    else if (charCount < 90) result = '😏';
    else if (charCount < 100) result = '😌';
    else if (charCount < 110) result = '🙃';
    else if (charCount < 120) result = '😉';
    else if (charCount < 130) result = '😊';
    else if (charCount < 140) result = '😀';
    else if (charCount < 150) result = '😃';
    else if (charCount < 160) result = '😄';
    else if (charCount < 170) result = '😁';
    else if (charCount < 180) result = '😬';
    else if (charCount < 200) result = '😎';
    else if (charCount < 250) result = '🤤';
    else if (charCount < 350) result = '🤩';
    else if (charCount < 450) result = '🥳';
    else if (charCount < 550) result = '🤠';
    else if (charCount < 650) result = '🫠';
    else if (charCount < 750) result = '😍';
    else if (charCount < 1000) result = '😓';
    else if (charCount < 1500) result = '😨';
    else if (charCount < 2000) result = '😥';
    else if (charCount < 2500) result = '😰';
    else if (charCount < 3000) result = '🫣';
    else if (charCount < 3500) result = '😠';
    else if (charCount < 4000) result = '😡';
    else if (charCount < 4500) result = '🤬';
    else if (charCount <= 5000) result = '🤢';
    else if (charCount > 5000) result = '🤯';
    return result;
}

export const boxImages = {
    circle_box_blue_ornament: '../assets/images/boxes/circle_box_blue_ornament.png',
    circle_box_blue: '../assets/images/boxes/circle_box_blue.png',
    circle_box_pink_ornament: '../assets/images/boxes/circle_box_pink_ornament.png',
    circle_box_pink: '../assets/images/boxes/circle_box_pink.png',
    circle_box_purple_ornament: '../assets/images/boxes/circle_box_purple_ornament.png',
    circle_box_purple: '../assets/images/boxes/circle_box_purple.png',
    circle_box_red_ornament: '../assets/images/boxes/circle_box_red_ornament.png',
    circle_box_red: '../assets/images/boxes/circle_box_red.png',
    circle_box_yellow_ornament: '../assets/images/boxes/circle_box_yellow_ornament.png',
    circle_box_yellow: '../assets/images/boxes/circle_box_yellow.png',

    heart_box_blue_ornament: '../assets/images/boxes/circle_heart_blue_ornament.png',
    heart_box_blue: '../assets/images/boxes/circle_heart_blue.png',
    heart_box_pink_ornament: '../assets/images/boxes/circle_heart_pink_ornament.png',
    heart_box_pink: '../assets/images/boxes/circle_heart_pink.png',
    heart_box_purple_ornament: '../assets/images/boxes/circle_heart_purple_ornament.png',
    heart_box_purple: '../assets/images/boxes/circle_heart_purple.png',
    heart_box_red_ornament: '../assets/images/boxes/circle_heart_red_ornament.png',
    heart_box_red: '../assets/images/boxes/circle_heart_red.png',
    heart_box_yellow_ornament: '../assets/images/boxes/circle_heart_yellow_ornament.png',
    heart_box_yellow: '../assets/images/boxes/circle_heart_yellow.png',

    star_box_blue_ornament: '../assets/images/boxes/circle_star_blue_ornament.png',
    star_box_blue: '../assets/images/boxes/circle_star_blue.png',
    star_box_pink_ornament: '../assets/images/boxes/circle_star_pink_ornament.png',
    star_box_pink: '../assets/images/boxes/circle_star_pink.png',
    star_box_purple_ornament: '../assets/images/boxes/circle_star_purple_ornament.png',
    star_box_purple: '../assets/images/boxes/circle_star_purple.png',
    star_box_red_ornament: '../assets/images/boxes/circle_star_red_ornament.png',
    star_box_red: '../assets/images/boxes/circle_star_red.png',
    star_box_yellow_ornament: '../assets/images/boxes/circle_star_yellow_ornament.png',
    star_box_yellow: '../assets/images/boxes/circle_star_yellow.png',

    square_box_blue_ornament: '../assets/images/boxes/circle_square_blue_ornament.png',
    square_box_blue: '../assets/images/boxes/circle_square_blue.png',
    square_box_pink_ornament: '../assets/images/boxes/circle_square_pink_ornament.png',
    square_box_pink: '../assets/images/boxes/circle_square_pink.png',
    square_box_purple_ornament: '../assets/images/boxes/circle_square_purple_ornament.png',
    square_box_purple: '../assets/images/boxes/circle_square_purple.png',
    square_box_red_ornament: '../assets/images/boxes/circle_square_red_ornament.png',
    square_box_red: '../assets/images/boxes/circle_square_red.png',
    square_box_yellow_ornament: '../assets/images/boxes/circle_square_yellow_ornament.png',
    square_box_yellow: '../assets/images/boxes/circle_square_yellow.png',
}

