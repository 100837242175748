import styles from './TextRewardModal.module.css';
import Modal from "../UI/Modal";
import ConfettiExplosion from "react-confetti-explosion";
import userStore from "../../store/userStore";
import {useTranslation} from "react-i18next";
import {useEffect} from "react";
import {sendEvent} from "../../util/firebase-analytics";

const TextRewardModal = (props) => {

    useEffect(()=>{
        sendEvent('action', {magic_text_reward: 1})
    }, [])

    const {t, i18n} = useTranslation();

    const isShowing = props.isShowing;
    const handleOnClose = () => {
        userStore.value.user.magicTextReward = 0;
        props.onClose();
    }

    return <Modal isShowing={isShowing} onBackClick={handleOnClose}>
        <div className={styles.modalContainer}>
            <ConfettiExplosion
                force={.5}
                duration={2000}
                particleCount={100}
                width={1000}
            />
            <h1 className={styles.modalTitle}>{t('magic message reward title')}</h1>
            <div className={styles.modalText}>
                {t('magic message reward desc').replace('#', userStore.value.user?.magicTextReward)}
            </div>
            <button onClick={handleOnClose} className={styles.modalButton}>{t('magic message reward button')}</button>

        </div>
    </Modal>;
}

export default TextRewardModal;
