import styles from './GradientButton.module.css';

const GradientButton = (props) => {
    const buttonText = props.text;
    const leftIcon = props.iconLeft;
    const rightIcon = props.iconRight;
    return <button
        className={`${styles.gradientButton} ${props.className ? props.className : ''}`}
        onClick={props.onClick}
    >
        {leftIcon && <img src={leftIcon} className={styles.icon}/>}
        {buttonText}
        {rightIcon && <img src={rightIcon} className={styles.icon}/>}
    </button>;
}

export default GradientButton;
