import styles from "./NavigationDesktop.module.css";
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";
import MenuNavItem from "./MenuNavItem";

const NavigationDesktop = (props) => {

    const {t, i18n} = useTranslation();

    return <div className={styles.navContainer}>
        <NavLink className={styles.logo} to="">txt.gift</NavLink>

        <div className={styles.menuItemsContainer}>
            <MenuNavItem text={t('nav home')} to={''}/>
            <MenuNavItem text={t('nav blog')} to={'blog'}/>
            <MenuNavItem text={t('nav create')} to={'create'}/>
        </div>
    </div>;
}

export default NavigationDesktop;
