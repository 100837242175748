import {signal} from "@preact/signals-react";

const loadingStore = signal({
    // Defaults Start at true to show loading, when has data set to false
    user: true,
    userGifts: false,
    gallery: false,
    blogs: false,
});

export default loadingStore;
