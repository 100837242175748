import styles from './ProfilePage.module.css';
import {useTranslation} from "react-i18next";
import UserProfile from "../../components/profile/UserSettings";
import UserSignIn from "../../components/profile/UserSignIn";
import {ClipLoader} from "react-spinners";
import userStore from '../../store/userStore';
import loadingStore from '../../store/loadingStore';
import {useEffect} from "react";
import {fetchUserGifts} from "../../util/backend-utils";
import {sendEvent} from "../../util/firebase-analytics";

function ProfilePage() {

    useEffect(()=>{
        // Page Open Analytics
        sendEvent('screenview', {screen_name: 'profile'})
    },[])

    if (loadingStore.value.user) {
        return <div className={styles.loaderContainer}>
            <ClipLoader
                color="#E646B6"
                loading={true}
                size={30}
                aria-label="Loading Spinner"
                data-testid="loader"
            />
        </div>;
    }

    return <div className={styles.container}>
        {userStore.value.user === null && <UserSignIn className={styles.content}/>}
        {userStore.value.user !== null && <UserProfile className={styles.content}/>}
    </div>
}

export default ProfilePage;
