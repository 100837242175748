import styles from './MagicImages.module.css';
import Select from "react-select";
import {useTranslation} from "react-i18next";
import {magicTextType} from "../../../util/MagicTemplates";
import React, {useEffect, useState} from "react";
import userStore from "../../../store/userStore";
import loadingStore from "../../../store/loadingStore";
import {ClipLoader} from "react-spinners";
import {Breathing, Image, Shimmer} from "react-shimmer";
import Selector from "awesome-selector";
import {selectorStyle} from "../../../util/utils";
import {fetchGallery} from "../../../util/backend-utils";

let MagicTypesTranslated = null;
let isFirstTimeShow = true;
const MagicImages = (props) => {
    const {t, i18n} = useTranslation();

    const [carouselType, setCarouselType] = useState(null);

    useEffect(()=>{
        // If Not Loading Gallery And Gallery Not Found, Fetch Images From Backend
        if (!loadingStore.value.gallery && !userStore.value.gallery) {
            fetchGallery();
        }
    }, [loadingStore.value.gallery])

    // Return Loading
    if (!userStore.value.gallery || loadingStore.value.gallery) {
        return  <div className={styles.magicImageContainer}>
            <label className={styles.title}>{t('magic images title')}</label>
            <ClipLoader color="#7155F6" loading={true} size={15} aria-label="Loading Spinner" data-testid="loader"/>
        </div>;
    }


    // Create Translated List If Not Exists
    if (MagicTypesTranslated === null) {

        MagicTypesTranslated = [];
        MagicTypesTranslated.push({id: 'all', name: t('magic images type show all')})

        for (const item of magicTextType()) {
            if (item.id === 'other') continue;
            MagicTypesTranslated.push({
                id: item.id,
                name: t(item['translationKey'])
            });
        }

    }

    const carouselImages = [];
    if (carouselType === null) {

    }
    else if (carouselType.id === 'all') {
        for (const templateFolder of userStore.value.gallery)
            for (const imagePaths of templateFolder.images)
                carouselImages.push({image: imagePaths['image'], displayImage: imagePaths['displayImage']});
    } else {
        const carouselByType = userStore.value.gallery.filter((item) => item.id === carouselType.id);
        for (const imagePaths of carouselByType[0].images) {
            carouselImages.push({image: imagePaths['image'], displayImage: imagePaths['displayImage'],})
        }
    }



    const handleChangeType = () => {
        new Selector({
            theme: 'light',
            title: '',
            options: MagicTypesTranslated,
            style: selectorStyle,
            onSelect: (id, name) => {
                const selectedItem = MagicTypesTranslated.find((item) => item.id === id);
                setCarouselType(selectedItem);
            }
        });
    };




    let currentSelectedTypeName = MagicTypesTranslated.find(item=>item.id ==='all').name;
    if (carouselType !== null)
        currentSelectedTypeName = MagicTypesTranslated.find(item=>item.id === carouselType.id).name;

    return <div className={styles.magicImageContainer}>

        <label className={styles.title}>{t('magic images title')}</label>
        <div className={styles.selectContainer}>
            {/*NO TYPE IS SELECTED*/}
            {carouselType === null && <div className={styles.selectButton} onClick={handleChangeType}>{t('magic images click to show')}</div>}
            {carouselType !== null && <div className={styles.selectButton} onClick={handleChangeType}>{currentSelectedTypeName}</div>}
        </div>


        {carouselImages.length > 0 &&
            <div className={styles.allImagesContainer}>
                {carouselImages.map((item, index) => {
                    return <div
                        key={item.image}
                        className={styles.swiperChildContainer}
                        onClick={()=>{
                        props.onImageSelected(item)
                    }}>
                    <Image
                        alt='Selected Image'
                        src={item.displayImage}
                        NativeImgProps={{ className: styles.swiperImage }}
                        fallback={<Breathing width={100} height={100}  />}
                    />
                    </div>
                })}
            </div>
        }

    </div>

}
export default MagicImages;
