import {NavLink} from "react-router-dom";
import styles from "./MenuNavItem.module.css";
import {useMediaQuery} from "react-responsive";

const MenuNavItem = (props) => {
    const isMobile = useMediaQuery({maxWidth: 767});

    const getNavLinkClass = (isActive) => {
        if (isMobile) {
            return isActive ? styles.menuItemActiveMobile : styles.menuItemMobile;
        }
        return isActive ? styles.menuItemActive : styles.menuItem;
    }

    const text = props.text;
    const to = props.to;
    return <NavLink className={({isActive}) => getNavLinkClass(isActive)} to={to} onClick={() => {
        if (props.onClick)
            props.onClick();
    }
    }>{text}</NavLink>;
}
export default MenuNavItem;
