import {initializeApp} from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyAsKYC0VY05WN_5-tkIGtlkyj_ELE_wXIU",
    authDomain: "txt.gift",
    databaseURL: "https://small-gifts-e9595-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "small-gifts-e9595",
    storageBucket: "small-gifts-e9595.appspot.com",
    messagingSenderId: "590063159036",
    appId: "1:590063159036:web:e23f28d5ce5547bca43979",
    measurementId: "G-QJC34Y75L8",
};
// Initialize Firebase
export const app = initializeApp(firebaseConfig);
