import styles from "./GiftPageLoading.module.css";
import {Player} from "@lottiefiles/react-lottie-player";
import gift_loading from "../../assets/loading_gift.json";
import React from "react";
import {useTranslation} from "react-i18next";

const GiftPageLoading = () => {
    const {t, i18n} = useTranslation();

    return <div className={styles.loading}>
        <Player src={gift_loading} className={styles.lottie} loop autoplay/>
        <div className={styles.loadingTitle}>{t('gift loading gift title')}</div>
    </div>;
}

export default GiftPageLoading;
