import {createPortal} from "react-dom";
import {AnimatePresence, motion} from "framer-motion";
import classes from './Modal.module.css';

export default function Modal({isShowing, children, onBackClick}) {
    return createPortal(
        <AnimatePresence>
            {isShowing &&

                <>
                    <motion.div
                        variants={{
                            hidden: {opacity: 0},
                            visible: {opacity: 1},
                        }}
                        transition={{duration: .3}}
                        initial="hidden"
                        animate="visible"
                        exit="hidden"
                        className={classes.backdrop}
                        onClick={onBackClick}
                    />

                    <div className={classes.modalContainer}>

                        <motion.dialog
                            variants={{
                                hidden: {opacity: 0, y: -30},
                                visible: {opacity: 1, y: 0},
                            }}
                            initial="hidden"
                            animate="visible"
                            exit="hidden"
                            open
                            className={classes.modal}
                        >
                            {children}
                        </motion.dialog>
                    </div>



                </>
            }
        </AnimatePresence>,
        document.getElementById("modal")
    );

}
