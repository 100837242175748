import styles from './AllBlogsPage.module.css';
import React, {useEffect, useState} from "react";
import loadingStore from "../../store/loadingStore";
import HomeSegmentSeparator from "../../components/home/HomeSegmentSeparator";
import {ClipLoader} from "react-spinners";
import {Breathing, Image} from "react-shimmer";
import {useTranslation} from "react-i18next";
import {NavLink} from "react-router-dom";
import userStore from "../../store/userStore";
import {fetchAllBlogs} from "../../util/backend-utils";
import {sendEvent} from "../../util/firebase-analytics";

const AllBlogsPage = (props) => {

    useEffect(()=>{
        // Page Open Analytics
        sendEvent('screenview', {screen_name: 'all_blogs'})
    },[])

    const {t, i18n} = useTranslation();
    // const [isLoading, setIsLoading] = useState(true);
    // const [blogs, setBlogs] = useState([]);

    useEffect(() => {

        if (!loadingStore.value.blogs && !userStore.value.blogs) {
            fetchAllBlogs();
        }
        // setIsLoading(loadingStore.value.blogs);
        /*if (uiStore.value.isLoadingBlog) return;

        const getBlogs = async () => {
            setIsLoading(true);
            const blogsRes = await GET('users/blogs');
            if (blogsRes.status === 200) {
                const sortedBlogs = blogsRes.blogsArr.sort((a,b) => {return new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime()}).reverse();
                setBlogs(sortedBlogs);
            }
            setIsLoading(false);
        }
        getBlogs();*/

    }, [loadingStore.value.blogs]);


    const convertDate = (dateString) => {
        const dateObject = new Date(dateString);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = dateObject.toLocaleDateString(undefined, options);
        return formattedDate;
    }

    return <div className={styles.container}>

        <HomeSegmentSeparator text={"Txt.gift Blog"}/>

        {loadingStore.value.blogs &&
            <div className={styles.loaderContainer}>
                <ClipLoader
                    color="#E646B6"
                    loading={true}
                    size={30}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />
            </div>
        }


        {!loadingStore.value.blogs && userStore.value.blogs &&
            <div className={styles.blogsContainer}>
                {userStore.value.blogs.map((blog) => {

                    return <NavLink to={`/blog/${blog.id}`} key={blog.id} className={styles.blogItemContainer}>

                        <Image
                            className={styles.blogItemImage}
                            alt='Selected Image'
                            src={blog.image}
                            NativeImgProps={{className: styles.blogItemImage}}
                            fallback={<Breathing width={300} height={150} NativeImgProps={{className: styles.blogItemImage}}/>}
                        />
                        <div className={styles.blogTextsContainer}>
                            <div className={styles.blogItemTitle}>{blog.title}</div>
                            <div className={styles.blogItemDate}>{convertDate(blog.createdDate)}</div>
                        </div>
                        <div className={styles.blogItemReadButton}>{t('blog read more')}</div>
                        </NavLink>
                })}
            </div>
        }

    </div>
}
export default AllBlogsPage;
