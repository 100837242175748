import {Unity, useUnityContext} from "react-unity-webgl";
import React, {useEffect, useState} from "react";
import styles from './GiftForm.module.css';
import {ClipLoader} from "react-spinners";
import {useTranslation} from "react-i18next";



const shapes = [
    {name: 'circle', symbol: '●', color: '#1EA362'},
    {name: 'star', symbol: '★', color: '#FFE047'},
    {name: 'heart', symbol: '❤︎', color: '#DD4B3E'},
    {name: 'square', symbol: '■', color: '#4A89F3'},
]
const colors = [
    {name: 'blue', file: '●'},
    {name: 'blue_ornament', file: '●'},
    {name: 'pink', file: '●'},
    {name: 'pink_ornament', file: '●'},
    {name: 'purple', file: '●'},
    {name: 'purple_ornament', file: '●'},
    {name: 'red', file: '●'},
    {name: 'red_ornament', file: '●'},
    {name: 'yellow', file: '●'},
    {name: 'yellow_ornament', file: '●'},
]


const GiftForm = (props) => {
    const {t, i18n} = useTranslation();

    let typeFromParent = 'circle_box_blue';
    if (props.typeName && props.typeName !== '') {
        typeFromParent = props.typeName;
    }
    const [shape, setShape] = useState(typeFromParent.split('_box_')[0]);
    const [color, setColor] = useState(typeFromParent.split('_box_')[1]);


    const {unityProvider, sendMessage, isLoaded} =
        useUnityContext({
            loaderUrl: "/unity/Build/magic_gift.loader.js",
            dataUrl: "/unity/Build/magic_gift.data",
            frameworkUrl: "/unity/Build/magic_gift.framework.js",
            codeUrl: "/unity/Build/magic_gift.wasm",
            streamingAssetsUrl: "StreamingAssets",
            companyName: "txt_gift",
            productName: "txt_gift_gift_viewer",
            productVersion: "1.0",
        });

    useEffect(() => {
        if (isLoaded) {
            sendMessage('Main', 'SetEditCamera');
            sendMessage('Main', 'InitGift', getCurrentFileName());
        }
    }, [isLoaded])

    useEffect(() => {
        if (isLoaded) {
            sendMessage('Main', 'InitGift', getCurrentFileName());
            props.onUpdate(getCurrentFileName());
        }
    }, [shape, color])

    const getCurrentFileName = () => `${shape}_box_${color}`;

    return <div className={styles.container}>
        <Unity unityProvider={unityProvider} devicePixelRatio={1} style={{width: '100%', pointerEvents: 'none'}}/>

        {/*LOADER*/}
        {!isLoaded && <div className={styles.loader}>
            <ClipLoader color="#7155F6" loading={true} size={50}/>
        </div>}

        {/*SHAPE SELECT*/}
        <label className={styles.title}>{t('create gift shape')}</label>
        <div className={styles.selectContainer}>
            {shapes.map((item) => {
                return <div key={item.name}
                            className={item.name === shape ? styles.selectItemSelected : styles.selectItem}
                            style={{color: item.color, borderColor: item.name === shape ? item.color : '#FFFFFF'}}
                            onClick={() => {
                                setShape(item.name)
                            }}
                >{item.symbol}</div>;
            })}
        </div>

        {/*COLOR SELECT*/}
        <label className={styles.title}>{t('create gift color')}</label>
        <div className={styles.selectContainer}>
            {colors.map((item, index) => {
                return <div key={item.name}
                            className={item.name === color ? styles.selectItemSelected : styles.selectItem}
                            onClick={() => {
                                setColor(item.name)
                            }}
                >
                    <div className={styles.colorImageContainer}>
                        <img className={styles.colorImage} src={`../boxes/${shape}_box_${item.name}.png`} />
                        {/*<img className={styles.colorImage} src={async ()=>{*/}
                        {/*    return await import('../../../assets/images/boxes/circle_box_blue.png')*/}
                        {/*}}/>*/}
                        {/*<img className={styles.colorImage} src={currentColor}/>*/}
                        {/*{colors[item] && <img className={styles.colorImage} src={colors[item]}/>}*/}
                    </div>
                </div>;
            })}
        </div>

    </div>
}
export default GiftForm;
