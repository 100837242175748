import styles from './BlogPage.module.css';
import React, {useEffect, useState} from "react";
import {GET} from "../../util/http";
import loadingStore from "../../store/loadingStore";
import HomeSegmentSeparator from "../../components/home/HomeSegmentSeparator";
import {ClipLoader} from "react-spinners";
import {useNavigate, useParams} from "react-router-dom";
import ErrorPage from "../error/ErrorPage";
import {sendEvent} from "../../util/firebase-analytics";
import GradientButton from "../../components/UI/designs/GradientButton";
import {useTranslation} from "react-i18next";

const BlogPage = (props) => {

    useEffect(()=>{
        // Page Open Analytics
        sendEvent('screenview', {screen_name: 'blog'})
    },[])

    const [blog, setBlog] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const params = useParams();
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();


    useEffect(() => {
        if (loadingStore.value.blogs) return;
        const getBlogById = async () => {
            setIsLoading(true);
            const blogRes = await GET('blogs/find/' + params.blogId);
            if (blogRes.status === 200)
                setBlog(blogRes.blog);
            else
                setError('Blog not found..');

            setIsLoading(false);
        }
        getBlogById();

    }, [loadingStore.value.user]);

    const convertDate = (dateString) => {
        const dateObject = new Date(dateString);
        const options = {year: 'numeric', month: 'long', day: 'numeric'};
        const formattedDate = dateObject.toLocaleDateString(undefined, options);
        return formattedDate;
    }


    if (error !== null) {
        return <ErrorPage/>
    }

    return <div className={styles.container}>

        {isLoading &&
            <div className={styles.loaderContainer}>
                <ClipLoader
                    color="#E646B6"
                    loading={true}
                    size={30}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />
            </div>
        }

        {!isLoading &&
            <>
                <HomeSegmentSeparator text={blog.title}/>
                <div className={styles.blogDate}>{blog.time} | {convertDate(blog.createdDate)}</div>
                {/*<img className={styles.blogMainImage} src={blog.image}/>*/}

                <div className={styles.blogContentContainer}>
                    {blog.content.map((item, index) => {
                        const contentItem = blog.content[index];
                        let currentContent = <></>;
                        if (contentItem.type === 'h1')
                            currentContent = <h1 className={styles.h1}>{contentItem.value}</h1>
                        if (contentItem.type === 'h2')
                            currentContent = <h2 className={styles.h2}>{contentItem.value}</h2>
                        if (contentItem.type === 'p')
                            currentContent = <p dangerouslySetInnerHTML={{ __html: contentItem.value }} className={styles.p}/>
                        if (contentItem.type === 'image')
                            currentContent = <img className={styles.image} src={contentItem.value} alt={"Image"}/>
                            // currentContent = <div style={{width: '100%', display:'flex', justifyContent:'center'}}>
                            //     <img className={styles.image} src={contentItem.value} alt={"Image"}/>
                            // </div>

                        return <div key={index} style={{width: '100%'}}>
                            {currentContent}
                            {contentItem.type === 'p' && <div className={styles.space}/>}
                        </div>
                    })}
                </div>



                <div className={styles.startButtonContainer}>
                    <GradientButton onClick={()=>{
                        navigate('/create');
                    }} className={styles.startButton} text={t('home create gift')}/>
                </div>
            </>
        }

    </div>
}
export default BlogPage;
