import React, {useEffect, useState} from 'react';
import styles from './FeedbackPage.module.css';
import feedback_image from '../../assets/images/feedback.png';
import {useTranslation} from "react-i18next";
import StarRatings from 'react-star-ratings'
import {POST} from '../../util/http';
import userStore from '../../store/userStore';
import {Player} from '@lottiefiles/react-lottie-player';
import lottie_feedback_loading from '../../assets/feedback_loading.json';
import Modal from "../../components/UI/Modal";
import Card from "../../components/UI/Card";
import {sendEvent} from "../../util/firebase-analytics";

const FeedbackPage = () => {

    useEffect(()=>{
        // Page Open Analytics
        sendEvent('screenview', {screen_name: 'feedback'})
    },[])

    const [loading, setLoading] = useState(false);
    const [rate, setRate] = useState(4);
    const [message, setMessage] = useState('');
    const handleRating = (rate) => {
        setRate(rate)
    }

    const {t, i18n} = useTranslation();

    const onFormSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        const email = userStore.value.user?.email;
        const form_data = {
            rate,
            message,
            email
        };
        await POST('users/feedback', form_data);

        setMessage('');
        setLoading(false);
    }

    return <div className={styles.container}>

        <img className={styles.image} src={feedback_image} alt={t('feedback title')}/>

        <div className={styles.containerInner}>
            <h1 className={styles.title}>{t('feedback title')}</h1>
            <span className={styles.text}>{t('feedback text')}</span>

            <Card>
                <form className={styles.form} onSubmit={onFormSubmit}>
                    <span className={styles.text}>{t('feedback give rating')}</span>
                    <StarRatings
                        rating={rate}
                        starRatedColor="#FFBE18"
                        starHoverColor="#FFBE18"
                        starEmptyColor="#D9D9D9"
                        starDimension="2rem"
                        changeRating={handleRating}
                        numberOfStars={5}
                        name='rating'
                    />
                    <span className={styles.text}>{t('feedback give rating extra')}</span>
                    <textarea className={styles.textArea}
                              value={message}
                              placeholder={t('feedback give rating extra hint')}
                              onChange={(element) => {
                                  setMessage(element.target.value);
                              }}/>

                    <div className={styles.buttonContainer}>
                        <button type="submit" className={styles.button}>{t('feedback submit')}</button>
                    </div>
                </form>
            </Card>
        </div>


        {/*LOADING*/}
        <Modal isShowing={loading} onBackClick={() => {
        }}>
            <div style={{width: '20rem'}}>
            <Player
                src={lottie_feedback_loading}
                className="player"
                loop
                autoplay
            />
            </div>
        </Modal>
    </div>
};

export default FeedbackPage;
