import userStore from '../store/userStore';
import loadingStore from '../store/loadingStore';
import {GET, POST, UPLOAD} from "./http";

export const setOrCreateGift = async (formData) => {

    const giftRes = await UPLOAD('gifts/create', formData);
    if (giftRes && giftRes.status === 200) {
        // Remove Old One If Exists In User Store
        if (userStore.value.user !== null) {
            const userGifts = userStore.value.user?.gifts;
            if (userGifts && userGifts.length > 0) {
                // Replace If User Have Gift With New Gift ID
                const userGiftsFiltered = userGifts.filter((item) => item.id !== giftRes.gift['id']);
                userGiftsFiltered.push(giftRes.gift);
                userStore.value.user.gifts = userGiftsFiltered;
            } else {
                // Create If First Gift Of User
                userStore.value.user.gifts = [giftRes.gift];
            }
        }
        // Share URL
        const baseUrl = process.env.REACT_APP_ENV === 'prod' ? 'https://txt.gift/g/' : 'http://localhost:3000/g/';
        return baseUrl + giftRes.gift['id'];
    } else {
        console.error('Could not create message, please try again later');
    }
    return null;
}

export const fetchUser = async (firebaseUser) => {
    // Show Loading
    loadingStore.value = {...loadingStore.value, user: true};
    // Fetch User
    const userResponse = await POST("users/get", {
        uid: firebaseUser.uid,
        email: firebaseUser.email,
        displayName: firebaseUser.displayName,
        photoURL: firebaseUser.photoURL,
    });
    // Update User
    if (userResponse.status === 200) {
        userStore.value.user = userResponse.user;
        userStore.value.user.photoURL = firebaseUser.photoURL;
    }
    // Hide Loading
    loadingStore.value = {...loadingStore.value, user: false};
}

export const fetchUserGifts = async () => {
    // Show Loading
    loadingStore.value = {...loadingStore.value, userGifts: true};
    // Fetch User Gifts
    try {
        const allGiftsRes = await GET("gifts/findAll");
        let gifts = allGiftsRes.status === 200 ? allGiftsRes.gifts : [];
        userStore.value.user['gifts'] = gifts;
    } catch (e) {
    }
    // Hide Loading
    loadingStore.value = {...loadingStore.value, userGifts: false};
}


export const fetchGallery = async () => {
    loadingStore.value = {...loadingStore.value, gallery: true};
    const galleryRes = await GET('gifts/imageGallery');
    if (galleryRes.status === 200) {
        userStore.value.gallery = galleryRes.imagesArray;
    }
    loadingStore.value = {...loadingStore.value, gallery: false};
}

export const fetchAllBlogs = async () => {
    loadingStore.value = {...loadingStore.value, blogs: true};
    const blogsRes = await GET('blogs/findAll');
    if (blogsRes.status === 200) {
        const sortedBlogs = blogsRes.blogsArr.sort((a, b) => {
            return new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime()
        }).reverse();
        userStore.value.blogs = sortedBlogs;
    }
    loadingStore.value = {...loadingStore.value, blogs: false};
}

