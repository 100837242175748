import {app} from "./firebase";
import {getAnalytics, logEvent, setUserId} from "firebase/analytics";

const analytics = getAnalytics(app);

const sendEvent = (eventName, params) => {
    params['app_version'] = process.env.REACT_APP_APP_VERSION;
    logEvent(analytics, eventName, params);
}

const setUser = (uid) => {
    setUserId(analytics, uid);
    sendEvent('login', {login_success: true,})
}

export {sendEvent, setUser};
