import {signal} from "@preact/signals-react";

const userStore = signal({
    token: null,
    user: null,
    gallery: null,
    blogs: null,
});

export default userStore;
