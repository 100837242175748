import styles from "./ImageFormYoutubeModal.module.css";
import Modal from "../UI/Modal";
import React, {useState} from "react";
import {validateYouTubeUrl} from "../../util/utils";
import {useTranslation} from "react-i18next";
import {ClipLoader} from "react-spinners";
import ReactPlayer from "react-player";

const ImageFormYoutubeModal = (props) => {

    const {t, i18n} = useTranslation();
    const [url, setUrl] = useState('');
    const [error, setError] = useState('');

    const handleOkClick = () =>{
        if (error === '') {
            props.onComplete(url);
        }
    }

    return <Modal isShowing={props.isShowing} onBackClick={props.onBackClick}>
        <form className={styles.youtubeModalContainer} onSubmit={(e)=>{e.preventDefault()}}>
            {/*YOUTUBE INPUT AREA*/}
            <label className={styles.title}>{t('create youtube modal title')}</label>
            <input className={(error === '') ? styles.inputYoutube : styles.inputYoutubeError}
                   placeholder="www.youtube.com/watch?v=abcdefghijk"
                   onChange={(element) => {
                       const value = element.target.value;
                       setUrl(value);
                       setError('');
                   }}/>

            {/*PLAYER TO DETECT IF URL IS VALID*/}
            <ReactPlayer
                playing={true}
                style={{position:'fixed', visibility: 'hidden', height:'1px !important', width:'1px !important'}}
                url={url}
                onError={()=>{setError(t('create youtube modal error'))}}
            />
            <label className={styles.hint}>{t('create youtube modal supported')}</label>

            {(error !== '') && <label className={styles.errorText}>{t('create youtube modal error')}</label>}
            <button className={styles.imageContainerButton} onClick={handleOkClick}>OK</button>
        </form>
    </Modal>;
}
export default ImageFormYoutubeModal;
