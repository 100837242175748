import styles from './HomeSegmentSeparator.module.css';

const HomeSegmentSeparator = (props) => {

    return <div className={styles.howItWorks}>
        <span className={styles.howItWorksText}>{props.text}
            <div className={styles.howItWorksLine}/>
        </span>

    </div>;
}

export default HomeSegmentSeparator;
