import i18n from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

// import translations from './assets/locals/en/translations.json';
// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
// const resources = translations;

import translationAR from '../src/assets/locals/ar/translations.json';
import translationBN from '../src/assets/locals/bn/translations.json';
import translationDE from '../src/assets/locals/de/translations.json';
import translationEN from '../src/assets/locals/en/translations.json';
import translationES from '../src/assets/locals/es/translations.json';
import translationFR from '../src/assets/locals/fr/translations.json';
import translationHI from '../src/assets/locals/hi/translations.json';
import translationIT from '../src/assets/locals/it/translations.json';
import translationHE from '../src/assets/locals/he/translations.json';
import translationJA from '../src/assets/locals/ja/translations.json';
import translationJV from '../src/assets/locals/jv/translations.json';
import translationKO from '../src/assets/locals/ko/translations.json';
import translationPA from '../src/assets/locals/pa/translations.json';
import translationPT from '../src/assets/locals/pt/translations.json';
import translationRU from '../src/assets/locals/ru/translations.json';
import translationTH from '../src/assets/locals/th/translations.json';
import translationTR from '../src/assets/locals/tr/translations.json';
import translationVI from '../src/assets/locals/vi/translations.json';
import translationZH from '../src/assets/locals/zh/translations.json';

export const Languages  = [
    {id: 'ar', name: '🇸🇦  العربية',},
    {id: 'bn', name: '🇧🇩  বাংলা',},
    {id: 'de', name: '🇩🇪  Deutsch',},
    {id: 'en', name: '🇬🇧  English',},
    {id: 'es', name: '🇪🇸  Español',},
    {id: 'fr', name: '🇫🇷  Français',},
    {id: 'hi', name: '🇮🇳  हिन्दी',},
    {id: 'it', name: '🇮🇹  Italiano',},
    {id: 'ja', name: '🇯🇵  日本語',},
    {id: 'jv', name: '🇮🇩  ꦧꦱꦗꦮ',},
    {id: 'he', name: '🇮🇱  עברית',},
    {id: 'ko', name: '🇰🇷  한국어',},
    {id: 'pa', name: '🇵🇰  ਪੰਜਾਬੀ',},
    {id: 'pt', name: '🇧🇷  Português',},
    {id: 'ru', name: '🇷🇺  Русский',},
    {id: 'zh', name: '🇨🇳  汉语',},
    {id: 'tr', name: '🇹🇷  Türkçe',},
    {id: 'vi', name: '🇻🇳  Tiếng Việt',},
    {id: 'th', name: '🇹🇭  ไทย',},
];
// export const supportedLanguages = ['ar', 'bn', 'de', 'en', 'es', 'fr', 'hi', 'it', 'ja', 'jv', 'ko', 'pa', 'pt', 'ru', 'zh', 'tr', 'vi', 'th'];

i18n
    .use(LanguageDetector) // passes i18n down to react-i18next
    .init({

        // Specify the fallback language
        // fallbackLng: 'en',
        lng: "en",

        // Key to store the user's language preference in localStorage
        detection: {
            order: ['localStorage', 'navigator'],
            lookupLocalStorage: 'i18nextLng',
        },

        // Your supported languages
        supportedLngs: Languages.map(item => item.id),

        // Resources for each language
        resources: {
            ar: {translation: translationAR},
            bn: {translation: translationBN},
            de: {translation: translationDE},
            en: {translation: translationEN},
            es: {translation: translationES},
            fr: {translation: translationFR},
            hi: {translation: translationHI},
            it: {translation: translationIT},
            he: {translation: translationHE},
            ja: {translation: translationJA},
            jv: {translation: translationJV},
            ko: {translation: translationKO},
            pa: {translation: translationPA},
            pt: {translation: translationPT},
            ru: {translation: translationRU},
            th: {translation: translationTH},
            tr: {translation: translationTR},
            vi: {translation: translationVI},
            zh: {translation: translationZH}
        },
    });

export default i18n;
