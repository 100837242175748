import "./App.css";
import {RouterProvider} from "react-router-dom";
import {createBrowserRouter} from "react-router-dom";
import ErrorPage from "./pages/error/ErrorPage";


// import CreatePage from "./pages/create/CreatePage";
// import GiftPage from "./pages/gift/GiftPage";
import CreatePage from "./pages/create/CreatePage";
import GiftPage from "./pages/gift/GiftPage";


import FeedbackPage from "./pages/feedback/FeedbackPage";
import ProfilePage from "./pages/profile/ProfilePage";
import HomePage from "./pages/home/HomePage";
import AboutUs from "./pages/about_us/AboutUs";
import LegalPage from "./pages/legal/LegalPage";
import RootLayout from "./pages/RootLayout";
import BlogPage from "./pages/blog/BlogPage";
import TodayPage from "./pages/today/TodayPage";
import AllBlogsPage from "./pages/blog_all/AllBlogsPage";

function App() {

    const router = createBrowserRouter([
        {
            path: "/",
            element: <RootLayout/>,
            errorElement: <ErrorPage/>,
            children: [
                {path: "", element: <HomePage/>},
                {path: "create", element: <CreatePage/>},
                {path: "create/:giftId", element: <CreatePage/>},
                {path: "blog", element: <AllBlogsPage/>},
                {path: "blog/:blogId", element: <BlogPage/>},

                // {path: "today", element: <TodayPage/>},
                {path: "profile", element: <ProfilePage/>},

                {path: "g/:giftId", element: <GiftPage/>},

                {path: "feedback", element: <FeedbackPage/>},
                {path: "policy/:section", element: <LegalPage/>},
                {path: "about", element: <AboutUs/>},
            ],
        },
    ]);

    return <RouterProvider router={router}/>;
}

export default App;
