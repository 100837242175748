import styles from "./LanguageButton.module.css";

const LanguageButton = (props) => {
    return <div className={styles.languageContainer} onClick={props.onClick}>
        <div className={styles.languageButton}>
            <div>{props.languageName}</div>
        </div>
    </div>
}
export default LanguageButton;
