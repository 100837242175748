import styles from "./UserDetails.module.css";
import userStore from '../../../store/userStore';
import {useTranslation} from "react-i18next";
import magic_wand from "../../../assets/images/magic_dark.png";
import {ClipLoader} from "react-spinners";
import {logOut} from "../../../util/firebase-auth";
import {useState} from "react";
import {POST} from "../../../util/http";


const UserDetails = (props) => {

    const {t, i18n} = useTranslation();
    const [isLoadingReceiveEmail, setIsLoadingReceiveEmail] = useState(false);

    const handleReceiveEmailsClick = async () => {
        setIsLoadingReceiveEmail(true);
        try {
            // Request Update
            const currentReceiveEmails = userStore.value.user.receiveEmails;
            const res = await POST('users/update', {receiveEmails: !currentReceiveEmails});

            // Update Store
            const user = userStore.value.user;
            if (res.status === 200)
                user.receiveEmails = !currentReceiveEmails;
            userStore.value = {...userStore.value, user}
        } catch (e) {}
        setIsLoadingReceiveEmail(false);
    }


    return <div className={styles.container}>

        {/*PROFILE DETAILS*/}
        <div className={styles.profileContainer}>
            <img src={userStore.value.user?.photoURL}
                 className={styles.profilePicture}
                 alt="Profile Picture"
                 referrerPolicy="no-referrer"
            />
            <div className={styles.profileContainerTexts}>
                <span className={styles.title}>{userStore.value.user?.displayName}</span>
                <span className={styles.desc}>{userStore.value.user?.email}</span>
            </div>
        </div>

        <div className={styles.seperator}/>
        {/*MAGIC TEXT REMAINING*/}
        <div className={styles.magicWandContainer}>
            <img src={magic_wand} className={styles.magicWandIcon}/>
            <div className={styles.text}>{t('profile magic text remains')} {userStore.value.user.magicTexts}</div>
        </div>


        <div className={styles.radioContainer} onClick={handleReceiveEmailsClick}>
            {/*CHECKBOX*/}
            <div className={userStore.value.user.receiveEmails ? styles.radioChecked : styles.radio}>
                {userStore.value.user.receiveEmails && <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path d="M17.1617 6.06483L7.48431 15.7423L3.04883 11.3068L4.18592 10.1697L7.48431 13.46L16.0246 4.92773L17.1617 6.06483Z" fill="white"/></svg>}
            </div>
            <div className={styles.text}>{t('profile text notification')}</div>

            {/*LOADER*/}
            {isLoadingReceiveEmail && <ClipLoader color="#7155F6" loading={true} size={15} aria-label="Loading Spinner" data-testid="loader"/>}
        </div>

        <div className={styles.seperator}/>
        <div className={styles.profileContainer}>
            <button onClick={logOut} className={styles.buttonSignOut}>{t('profile sign out')}</button>
        </div>


    </div>;
}
export default UserDetails;
