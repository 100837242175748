import styles from './Navigation.module.css';
import {useMediaQuery} from 'react-responsive';
import NavigationDesktop from "./NavigationDesktop";
import NavigationMobile from "./NavigationMobile";
import {useTranslation} from "react-i18next";
import Selector from "awesome-selector";
import {saveSelectedLanguage, selectorStyle} from '../../util/utils';
import {Languages} from "../../i18n";
import LanguageButton from "./LanguageButton";
import ProfileButton from "./ProfileButton";

const Navigation = (props) => {
    const isMobile = useMediaQuery({maxWidth: 767});

    const {t, i18n} = useTranslation();
    const currentLang = Languages.find(obj => obj.id === i18n.language).name;


    const languageClickHandler = () =>{
        new Selector({
            theme: 'light',
            title: t('profile language title'),
            options: Languages,
            style: selectorStyle,
            onSelect: (id, name) => {
                // console.log(id, name);
                saveSelectedLanguage(id);
                i18n.changeLanguage(id);
            }
        });
    }

    return <div className={styles.container}>

        {/*Desktop Menu*/}
        {!isMobile && <NavigationDesktop/>}

        {/*Mobile Menu*/}
        {isMobile && <NavigationMobile/>}

        <div className={styles.langAndProfile}>
        {/*LANGUAGES*/}
        <LanguageButton languageName={currentLang} onClick={languageClickHandler}/>

        {/*SIGN IN*/}
        <ProfileButton/>
        </div>

    </div>;
}
export default Navigation;
