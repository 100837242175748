import {useTranslation} from "react-i18next";
import styles from './UserSignIn.module.css';
import {signInWithGoogle} from "../../util/firebase-auth";
import google_icon from '../../assets/images/google.png';

const UserSignIn = (props) => {
    const {t, i18n} = useTranslation();

    const handleGoogleSignInClick = () => {
        signInWithGoogle();
    }
    // const handleAppleClickSignIn = () => {
    //     signInWithApple();
    // }

    return <div className={styles.container}>
        <span className={styles.text}>{t('profile text logged out')}</span>


        <div className={styles.getStartedContainer}>
            <h1 className={styles.getStartedTitle}>{t('profile text get started title')}</h1>
            <span className={styles.getStartedText}>{t('profile text get started text')}</span>
            <button className={styles.googleButton} onClick={handleGoogleSignInClick}>
                {/*GOOGLE ICON*/}
                <img src={google_icon} className={styles.icon}/>
                <span className={styles.googleButtonText}>{t('profile text get started button')}</span>
            </button>

            {/*<button className={styles.appleButton} onClick={handleAppleClickSignIn}>*/}
            {/*    /!*Apple ICON*!/*/}
            {/*    <img src={apple_icon} className={styles.icon}/>*/}
            {/*    <span className={styles.appleButtonText}>Sign in with Apple</span>*/}
            {/*</button>*/}
        </div>
    </div>;
}
export default UserSignIn;
