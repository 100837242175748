import styles from './HomePage.module.css';
import HomeHeader from "../../components/home/HomeHeader";
import HomeSegmentSeparator from "../../components/home/HomeSegmentSeparator";
import HomeSegment from "../../components/home/HomeSegment";
import home_image_1 from '../../assets/images/home_1.png'
import home_image_2 from '../../assets/images/home_2.png'
import home_image_3 from '../../assets/images/home_3.png'
import Circle from "../../components/UI/Circle";
import {useMediaQuery} from "react-responsive";
import {useNavigate} from "react-router-dom";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {sendEvent} from "../../util/firebase-analytics";

const HomePage = (props) => {

    useEffect(()=>{
        // Page Open Analytics
        sendEvent('screenview', {screen_name: 'home'})
    },[])

    const isMobile = useMediaQuery({maxWidth: 767});
    const navigate = useNavigate();
    const {t, i18n} = useTranslation();


    const createGiftButtonHandler = () => {
        navigate('/create');
    }

    return <>

        {!isMobile &&
            <>
                {/*<Circle color={"#EC7B8A"} right={'25rem'} top={'45rem'} opacity={0.4}/>*/}
                {/*<Circle color={"#27DEBF"} left={'25rem'} top={'32rem'} opacity={0.2}/>*/}
                {/*<Circle color={"#FDF2D7"} left={'30rem'} top={'100rem'} opacity={0.6}/>*/}
                {/*<Circle color={"#5A56E9"} left={'20rem'} top={'95rem'} opacity={0.6}/>*/}
            </>
        }


        <div className={styles.home}>
            <div className={styles.rings}/>

            {/*SEGMENT 0 __GIFT */}
            <HomeHeader onButtonClick={createGiftButtonHandler}/>


            {/*HOW IT WORKS*/}
            {/*<HomeSegmentSeparator text={t('home segment separator how it works')}/>*/}
            {/*SEGMENT 1 __ADD MESSAGE */}
            {/*<HomeSegment*/}
            {/*    image={home_image_2}*/}
            {/*    header={t('home segment1 title')}*/}
            {/*    text={t('home segment1 description')}*/}
            {/*    buttonText={t('home create gift')}*/}
            {/*    onButtonClick={createGiftButtonHandler}*/}
            {/*    isReverse={false}*/}
            {/*/>*/}


            {/*SEGMENT 2 __CHOOSE IMAGE */}
            {/*<HomeSegment*/}
            {/*    image={home_image_1}*/}
            {/*    header={t('home segment2 title')}*/}
            {/*    text={t('home segment2 description')}*/}
            {/*    buttonText={t('home create gift')}*/}
            {/*    onButtonClick={createGiftButtonHandler}*/}
            {/*    isReverse={true}*/}
            {/*/>*/}

            {/*SEGMENT 3 __SHARE & ENJOY */}
            {/*<HomeSegment*/}
            {/*    image={home_image_3}*/}
            {/*    header={t('home segment3 title')}*/}
            {/*    text={t('home segment3 description')}*/}
            {/*    buttonText={t('home create gift')}*/}
            {/*    onButtonClick={createGiftButtonHandler}*/}
            {/*    isReverse={false}*/}
            {/*/>*/}

        </div>

    </>;
}

export default HomePage;
