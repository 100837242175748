import styles from './StepLine.module.css';

const StepLine = (props) => {

    const width = props.width;

    return <>
        <div className={styles.container}>
            <div className={styles.line} style={{width: width+'%'}}/>
        </div>
    </>
}

export default StepLine;
