import styles from './ImageForm.module.css';
import React, {useRef, useState} from "react";
import uploadIco from '../../../assets/images/upload.png';
import {useTranslation} from "react-i18next";
import {Breathing, Image} from 'react-shimmer'
import MagicImages from "../templates/MagicImages";
import Modal from "../../UI/Modal";
import ImageFormYoutubeModal from "../ImageFormYoutubeModal";
import MessageFormEmptyImage from "./MessageFormEmptyImage";
import MessageFormMedia from "./MessageFormMedia";

const ImageForm = (props) => {

    const {t, i18n} = useTranslation();
    const [showYoutubeModal, setShowYoutubeModal] = useState(false);

    const imageData = {
        image: props.gift.image,
        displayImage: props.gift.displayImage,
        imageType: props.gift.imageType,
        youtube: props.gift.youtube,
    }

    const handleImageDropped = (file) => {
        if (file && file.type.startsWith('image/')) {
            props.onUpdate('image', {
                image: file,
                displayImage: '',
                imageType: 'file',
            });
        }
    }

    const handleDrop = (event) => {
        event.preventDefault();
        handleImageDropped(event.dataTransfer.files[0]);
    };
    const handleFileInput = (event) => {
        handleImageDropped(event.target.files[0]);
    };

    const handleRemoveMedia = () => {
        props.onUpdate('image', {
            image: '',
            displayImage: '',
            imageType: '',
            youtube: '',
        });
    };


    // NO IMAGE OR YOUTUBE SELECTED
    let UploadImageContent = <MessageFormEmptyImage
        onYoutubeClick={()=>{setShowYoutubeModal(true)}}
        handleFileInput={handleFileInput}
    />;


    // SHOW SELECTED IMAGE OR YOUTUBE
    if (imageData.imageType !== '' || imageData.youtube !== '') {
        UploadImageContent = <MessageFormMedia imageData={imageData} onRemoveMediaClick={handleRemoveMedia}/>;

        // UploadImageContent = <div className={styles.imageContainer}>
        //     {/*REMOVE BUTTON*/}
        //     <div className={styles.removeButtonContainer}>
        //         <button className={styles.removeButton} onClick={handleRemoveMedia}>X</button>
        //     </div>
        //     {/*IMAGE*/}
        //     <Image
        //         className={styles.selectedImage}
        //         alt={t('home image input upload alt')}
        //         src={imageData.imageType === 'file' ? URL.createObjectURL(imageData.image) : imageData.displayImage}
        //         NativeImgProps={{className: styles.selectedImage}}
        //         fallback={<Breathing width={200} height={200} NativeImgProps={{className: styles.selectedImage}}/>}
        //     />
        // </div>
    }

    return <div className={styles.container}>
        {/*PAGE CONTENT*/}
        <form className={styles.form} onSubmit={(e) => e.preventDefault()}>

            {/*UPLOAD IMAGE*/}
            <label className={styles.title}>{t('create upload image')}
                <label className={styles.titleOptional}>({t('create upload image optional')})</label>
            </label>
            <label
                onDrop={handleDrop}
                onDragOver={(e) => e.preventDefault()}
                htmlFor="image-input" // Associate label with the hidden file input
            >
                {UploadImageContent}
            </label>
        </form>


        {/*YOUTUBE MODAL*/}
        <ImageFormYoutubeModal isShowing={showYoutubeModal}
                               onBackClick={() => {
                                   setShowYoutubeModal(false)
                               }}
                               onComplete={(url) => {
                                   console.log('url', url)
                                   props.onUpdate('youtube', url);
                                   setShowYoutubeModal(false)
                               }}/>
    </div>;
}

export default ImageForm;
