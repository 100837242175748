import styles from "./BackNextButtons.module.css";
import {useTranslation} from "react-i18next";

const BackNextButtons = (props) => {

    const {t, i18n} = useTranslation();

    const showNext = props.showNext;
    const showBack = props.showBack;

    const onNextClick = props.onNextClick;
    const onBackClick = props.onBackClick;

    return  <div className={styles.buttonsContainer}>
        <button type="submit" onClick={onBackClick} className={showBack ? styles.buttonBack : styles.buttonBackHidden}>{t('create back button')}</button>
        <button type="submit" onClick={onNextClick} className={showNext ? styles.buttonNext : styles.buttonNextHidden}>{t('create next button')}</button>
    </div>;
}
export default BackNextButtons;
