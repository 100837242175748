import styles from './NavigationMobile.module.css';
import React, {useState} from "react";
import {Squeeze as Hamburger} from "hamburger-react";
import {motion} from "framer-motion";
import {AnimatePresence} from "framer-motion";
import {useTranslation} from "react-i18next";
import MenuNavItem from "./MenuNavItem";

const NavigationMobile = (props) => {

    const [menuOpen, setMenuOpen] = useState(false);
    const {t, i18n} = useTranslation();

    const closeMobileMenu = () => {
        setMenuOpen(false);
    }

    return <div className={styles.navContainer}>

        <div className={styles.hamburgerContainer}>
            <Hamburger toggled={menuOpen} toggle={setMenuOpen} size={28}/>
        </div>

        {menuOpen && (<div
            className={styles.backdrop}
            onClick={() => {
                setMenuOpen(false);
            }}
        />)}

        <AnimatePresence>
            {menuOpen && <>
                <motion.div
                    transition={{duration: 0.2, type: "spring", mass: 0.1,}}
                    initial={{opacity: 0, scaleY: 0, originY: 0}}
                    animate={{opacity: 1, scaleY: 1, originY: 0}}
                    exit={{opacity: 0, scaleY: 0, originY: 0}}
                    open
                    className={styles.menuItemsContainerMobile}
                >
                    <div className={styles.menuItemsList}>
                        <MenuNavItem text={t('nav home')} to={''} onClick={closeMobileMenu}/>
                        <MenuNavItem text={t('nav blog')} to={'blog'}  onClick={closeMobileMenu}/>
                        {/*<MenuNavItem text={t('nav today')} to={'today'}/>*/}
                        <MenuNavItem text={t('nav create')} to={'create'} onClick={closeMobileMenu}/>
                    </div>
                </motion.div>
            </>}
        </AnimatePresence>

    </div>;
}

export default NavigationMobile;
