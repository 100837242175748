import styles from './Footer.module.css';
import {NavLink} from "react-router-dom";
import {clickEmail} from '../../util/utils';
import {useTranslation} from "react-i18next";

const Footer = (props) => {

    const {t, i18n} = useTranslation();

    return <div className={styles.footer}>

        <div className={styles.container}>
            <div className={styles.containerInner}>
                <span className={styles.txtLogo}>{(t('app name')).toLowerCase()}</span>
                <span className={styles.desc}>{t('footer description')}</span>
            </div>

            <div className={styles.seperator}></div>

            <div className={styles.containerLinks}>
                <NavLink className={styles.link} to="about" >{t('footer about us')}</NavLink>
                <NavLink className={styles.link} to="feedback" >{t('footer feedback')}</NavLink>
                <button className={styles.link} onClick={clickEmail} >{t('footer contact us')}</button>
            </div>

            <div className={styles.containerLinks}>
                <NavLink className={styles.link} to="policy/cookies" >Cookies</NavLink>
                <NavLink className={styles.link} to="policy/terms" >Terms Of Service</NavLink>
                <NavLink className={styles.link} to="policy/privacy" >Privacy Policy</NavLink>
                <NavLink className={styles.link} to="policy/dmca" >DMCA</NavLink>
            </div>

        </div>
        <span className={styles.rights}>Copyright © <span className={styles.rightsBold}>txt.gift</span> | All rights reserved to <span className={styles.rightsBold}>txt.gift</span> {new Date().getFullYear()}</span>
        <span className={styles.madeWithLove}>Made with love 🥰</span>

    </div>;
}

export default Footer;
