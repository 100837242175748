import {QueryClient} from '@tanstack/react-query';
import {refreshAuthToken} from "./firebase-auth";
import userStore from '../store/userStore';

export const queryClient = new QueryClient();


export async function UPLOAD(path, form_data) {
    await refreshAuthToken();
    await waitForNonNullValue(() => userStore.value.token);

    return await performRequest(path, {
        method: 'POST',
        body: form_data,
        headers: {
            'Authorization': 'Bearer ' + userStore.value.token,
        },
    });
}

export async function GET(path) {
    await refreshAuthToken();
    await waitForNonNullValue(() => userStore.value.token);

    return await performRequest(path, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + userStore.value.token,
        },
    });
}

export async function PUT(path) {
    await refreshAuthToken();
    await waitForNonNullValue(() => userStore.value.token);

    return await performRequest(path, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + userStore.value.token,
        },
    });
}


export async function POST(path, body) {
    await refreshAuthToken();
    await waitForNonNullValue(() => userStore.value.token);

    return await performRequest(path, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + userStore.value.token,
        },
    });
}


async function waitForNonNullValue(callback) {
    let value = null;
    while (!value) {
        value = callback();
        if (!value) {
            await new Promise(resolve => setTimeout(resolve, 1000)); // Adjust the timeout as needed
        }
    }
}

async function performRequest(path, initConfig) {
    const URL = process.env.REACT_APP_SERVER_URL + path;
    const response = await fetch(URL, initConfig);

    if (!response.ok) {
        const error = new Error('An error occurred while creating the event');
        error.code = response.status;
        error.info = await response.json();
        throw error;
    }

    const res = await response.json();
    return res;
}
