import styles from "./RootLayout.module.css";
import Navigation from "../components/navigation/Navigation";
import Footer from "../components/footer/Footer";
import React, {useEffect, useState} from "react";
import {Outlet, useLocation} from "react-router-dom";
import TextRewardModal from "../components/text_reward/TextRewardModal";
import loadingStore from "../store/loadingStore";
import userStore from "../store/userStore";
import {refreshAuthToken} from "../util/firebase-auth";
import {initLanguage} from "../util/utils";
import ScrollToTop from "..//components/UI/ScrollToTop";
import {Helmet} from "react-helmet";

const RootLayout = (props) => {

    // Gift page should not show Header and Footer
    const location = useLocation();
    const [showReward, setShowReward] = useState(false);
    const isShowGiftPage = location.pathname.startsWith('/g/');

    // Should Show Magic Message Reward Modal
    useEffect(() => {
        if (loadingStore.value.user) return;
        // Is Already Showing
        if (showReward) return;
        // In Gift Page, Only Show Gift
        if (isShowGiftPage) return;

        const magicTextReward = userStore.value.user?.magicTextReward;
        if (magicTextReward && Number(magicTextReward) > 0) {
            setTimeout(() => {
                setShowReward(true);
            }, 500);
        }
    }, [location])


    // User has switched back to the tab
    useEffect(() => {
        window.addEventListener("focus", refreshAuthToken);
        refreshAuthToken();
        initLanguage();
        // Specify how to clean up after this effect:
        return () => {
            window.removeEventListener("focus", refreshAuthToken);
        };
    }, []);

    return <>
        <header>
            <Helmet>
                <title>Txt.Gift: Eco-Friendly Text Messages Wrapped in Gifts | Send Digital Love for Free</title>
                <meta name="description" content="Eco-Friendly Txt.Gift: Surprise Boxes for Birthday Gifts & More | Send Digital Love for Free!"/>
                <meta name="keywords" content="Eco-friendly gifting, text message gifts, sustainable digital gifts, online gift wrapping, environmentally friendly messages, green gifting platform, digital love, eco-conscious consumers, free gift sending, box of surprises, box gift birthday, girft me, gifty online, gift delivered, presents delivered, love gift, gift virtual card, card gift online, gift boxes"/>
                <meta name="robots" content="index, follow"/>
                <meta name="author" content="Adele Txt.gift owner"/>
                <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
                <meta property="og:title" content="Txt.Gift: Send Eco-Friendly Text Messages as Digital Gifts for Free"/>
                <meta property="og:description" content="Join Txt.Gift to send eco-conscious text messages wrapped in digital gifts for free. Connect with loved ones sustainably and spread joy worldwide."/>
                <meta property="og:image" content="https://txt.gift/logo192.png"/>
                <meta property="og:url" content="https://txt.gift"/>
            </Helmet>
            <div className={isShowGiftPage ? styles.BackgroundGradientGiftPage : styles.BackgroundGradient}/>
            {!isShowGiftPage && <Navigation/>}
        </header>
        <main style={{paddingTop: '5rem'}}>
            <ScrollToTop>
                <Outlet/>
                <TextRewardModal isShowing={showReward} onClose={() => setShowReward(false)}/>
            </ScrollToTop>
        </main>
        <footer>
            {!isShowGiftPage && <Footer/>}
        </footer>
    </>;
}
export default RootLayout;
