import styles from './ShareSocial.module.css';
import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookMessengerIcon,
    FacebookMessengerShareButton,
    FacebookShareButton,
    FacebookShareCount,
    GabIcon,
    GabShareButton,
    HatenaIcon,
    HatenaShareButton,
    HatenaShareCount,
    InstapaperIcon,
    InstapaperShareButton,
    LineIcon,
    LineShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    LivejournalIcon,
    LivejournalShareButton,
    MailruIcon,
    MailruShareButton,
    OKIcon,
    OKShareButton,
    OKShareCount,
    PinterestIcon,
    PinterestShareButton,
    PinterestShareCount,
    PocketIcon,
    PocketShareButton,
    RedditIcon,
    RedditShareButton,
    RedditShareCount,
    TelegramIcon,
    TelegramShareButton,
    TumblrIcon,
    TumblrShareButton,
    TumblrShareCount, TwitterIcon,
    TwitterShareButton,
    ViberIcon,
    ViberShareButton,
    VKIcon,
    VKShareButton,
    VKShareCount,
    WeiboIcon,
    WeiboShareButton,
    WhatsappIcon,
    WhatsappShareButton,
    WorkplaceIcon,
    WorkplaceShareButton,
    XIcon,
} from "react-share";
import {useTranslation} from "react-i18next";
import {useEffect} from "react";
import {sendEvent} from "../../../util/firebase-analytics";

const ShareSocial = (props) => {

    useEffect(()=>{
        sendEvent('create_end', {social_share: 1})
    }, [])

    const {t, i18n} = useTranslation();
    const title = t('create share social title');
    const shareUrl = props.url;

    return <div className={styles.Demo__container}>
        <FacebookShareButton url={shareUrl} className={styles['Demo__some-network__share-button']}>
            <FacebookIcon size={45} round/>
        </FacebookShareButton>

        <TwitterShareButton url={shareUrl} title={title} className={styles['Demo__some-network__share-button']}>
            <XIcon size={45} round/>
        </TwitterShareButton>

        <TelegramShareButton url={shareUrl} title={title} className={styles['Demo__some-network__share-button']}>
            <TelegramIcon size={45} round/>
        </TelegramShareButton>

        <WhatsappShareButton url={shareUrl} title={title} separator=":: "
                             className={styles['Demo__some-network__share-button']}>
            <WhatsappIcon size={45} round/>
        </WhatsappShareButton>

        <LinkedinShareButton url={shareUrl} className={styles['Demo__some-network__share-button']}>
            <LinkedinIcon size={45} round/>
        </LinkedinShareButton>

        <RedditShareButton url={shareUrl} title={title} windowWidth={660} windowHeight={460}
                           className={styles['Demo__some-network__share-button']}>
            <RedditIcon size={45} round/>
        </RedditShareButton>

        {/*<GabShareButton url={shareUrl} title={title} windowWidth={660} windowHeight={640} className={styles['Demo__some-network__share-button>*/}
        {/*    <GabIcon size={45} round/>*/}
        {/*</GabShareButton>*/}

        <TumblrShareButton url={shareUrl} title={title} className={styles['Demo__some-network__share-button']}>
            <TumblrIcon size={45} round/>
        </TumblrShareButton>

        {/*<LivejournalShareButton url={shareUrl} title={title} description={shareUrl} className={styles['Demo__some-network__share-button>*/}
        {/*    <LivejournalIcon size={45} round/>*/}
        {/*</LivejournalShareButton>*/}

        {/*<MailruShareButton url={shareUrl} title={title} className={styles['Demo__some-network__share-button>*/}
        {/*    <MailruIcon size={45} round/>*/}
        {/*</MailruShareButton>*/}

        {/*<EmailShareButton url={shareUrl} subject={title} body="body" className={styles['Demo__some-network__share-button>*/}
        {/*    <EmailIcon size={45} round/>*/}
        {/*</EmailShareButton>*/}

        <ViberShareButton url={shareUrl} title={title} className={styles['Demo__some-network__share-button']}>
            <ViberIcon size={45} round/>
        </ViberShareButton>

        {/*<WorkplaceShareButton url={shareUrl} quote={title} className={styles['Demo__some-network__share-button>*/}
        {/*    <WorkplaceIcon size={45} round/>*/}
        {/*</WorkplaceShareButton>*/}

        {/*<LineShareButton url={shareUrl} title={title} className={styles['Demo__some-network__share-button']}>*/}
        {/*    <LineIcon size={45} round/>*/}
        {/*</LineShareButton>*/}


        {/*<PinterestShareButton url={shareUrl} title={title} className={styles['Demo__some-network__share-button']}>*/}
        {/*    <LineIcon size={45} round/>*/}
        {/*</PinterestShareButton>*/}

        {/*<PocketShareButton url={shareUrl} title={title} className={styles['Demo__some-network__share-button>*/}
        {/*    <PocketIcon size={45} round/>*/}
        {/*</PocketShareButton>*/}

        {/*<InstapaperShareButton url={shareUrl} title={title} className={styles['Demo__some-network__share-button>*/}
        {/*    <InstapaperIcon size={45} round/>*/}
        {/*</InstapaperShareButton>*/}

        {/*<HatenaShareButton url={shareUrl} title={title} windowWidth={660} windowHeight={460} className={styles['Demo__some-network__share-button>*/}
        {/*    <HatenaIcon size={45} round/>*/}
        {/*</HatenaShareButton>*/}
    </div>;
}

export default ShareSocial;
