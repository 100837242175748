import styles from './MessageFormEmptyImage.module.css';
import uploadIco from "../../../assets/images/upload.png";
import React from "react";
import {useTranslation} from "react-i18next";

const MessageFormEmptyImage = (props) => {
    const {t, i18n} = useTranslation();

    return <div className={styles.imageContainer}>
        {/*YOUTUBE BUTTON*/}
        <button className={styles.imageYouTubeButton} onClick={() => {
            props.onYoutubeClick();
        }}>▸
        </button>

        {/*EMPTY IMAGE CONTAINER*/}
        <img className={styles.imageContainerIco} src={uploadIco} alt={t('home image input upload button')}/>
        <span className={styles.imageContainerTitle}>{t('home image input drop here')}</span>
        <span className={styles.imageContainerDesc}>{t('home image input drag and drop')}</span>
        <div className={styles.imageContainerButton}>{t('home image input upload button')}</div>
        <input
            type="file"
            name="file"
            accept="image/*"
            style={{display: 'none'}}
            id="image-input"
            onChange={props.handleFileInput}
        />
    </div>;
}
export default MessageFormEmptyImage;
