import styles from './MessageFormMedia.module.css';
import {Breathing, Image} from "react-shimmer";
import React from "react";
import {useTranslation} from "react-i18next";
import ReactPlayer from "react-player";

const MessageFormMedia = (props) => {
    const {t, i18n} = useTranslation();

    const youtube_parser = (url) => {
        var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        var match = url.match(regExp);
        return (match && match[7].length == 11) ? match[7] : false;
    }

    const imageData = props.imageData;
    const isMediaImage = imageData.imageType && imageData.imageType !== '';
    const isMediaYoutube = imageData.youtube && imageData.youtube !== '';

    return <div className={styles.imageContainer}>
        {/*REMOVE BUTTON*/}
        <div className={styles.removeButtonContainer}>
            <button className={styles.removeButton} onClick={(e) => {
                e.preventDefault();
                props.onRemoveMediaClick();
            }}>X
            </button>
        </div>

        {/*IMAGE*/}
        {isMediaImage &&
            <Image
                className={styles.selectedImage}
                alt={t('home image input upload alt')}
                src={imageData.imageType === 'file' ? URL.createObjectURL(imageData.image) : imageData.displayImage}
                NativeImgProps={{className: styles.selectedImage}}
                fallback={<Breathing width={200} height={200} NativeImgProps={{className: styles.selectedImage}}/>}
            />
        }

        {/*YOUTUBE*/}
        {isMediaYoutube &&
            <div className={styles.youtubeContainer}>
                <ReactPlayer url={imageData.youtube} playing={true} controls={true}/>
            </div>
        }

    </div>;
}
export default MessageFormMedia;
