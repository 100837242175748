import {useLocation} from "react-router-dom";
import {useLayoutEffect} from "react";

const ScrollToTop = (props) => {
    const Wrapper = ({children}) => {
        const location = useLocation();
        useLayoutEffect(() => {
            // Ignore Privacy Policy
            if (location.pathname && !location.pathname.startsWith('/policy'))
                document.documentElement.scrollTo(0, 0);

        }, [location.pathname]);
        return children;
    };

    return <Wrapper>{props.children}</Wrapper>;
}
export default ScrollToTop;
