const magicTextType = () => {
    const items = [];

    const halloween = new Date(new Date().getFullYear(), 9, 31);
    if (isWeekBeforeOrAfter(halloween, 7, 2)) {
        items.push({
            id: 'halloween',
            name: '🎃  Halloween',
            translationKey: 'magic message create magic halloween label',
            images: [
                '',
            ]
        });
    }

    const thanksgivingDay = new Date(new Date().getFullYear(), 10, 25); // Fourth Thursday in November
    if (isWeekBeforeOrAfter(thanksgivingDay, 7, 2)) {
        items.push({
            id: 'thanksgiving',
            name: '🦃  Thanks Giving',
            translationKey: 'magic message create magic thanksgiving label'
        });
    }

    const christmas = new Date(new Date().getFullYear(), 11, 25); // Month is zero-based (11 represents December)
    if (isWeekBeforeOrAfter(christmas, 7, 2)) {
        items.push({
            id: 'christmas',
            name: '🎄  Christmas',
            translationKey: 'magic message create magic christmas label'
        });
    }

    const isNewYear = new Date(new Date().getFullYear() + 1, 0, 1); // Month is zero-based (11 represents December)
    if (isWeekBeforeOrAfter(isNewYear, 7, 2))
        items.push({
            id: 'new_year',
            name: '🎊  New Year',
            translationKey: 'magic message create magic new year label'
        });

    items.push({
            id: 'birthday', name: '🎉  Birthday',
            translationKey: 'magic message create magic birthday label',
            extraInput: [
                {
                    id: 'age',
                    type: 'number',
                    name: 'Age',
                    placeHolder: 'Ex. 26',
                    translationKey: 'magic message create magic age title',
                    translationPlaceHolder: 'magic message create magic age place holder',
                },
            ]
        },
        {
            id: 'wedding',
            name: '💍  Wedding',
            translationKey: 'magic message create magic wedding label',
        },
        {
            id: 'baby',
            name: '🍼  New Baby',
            translationKey: 'magic message create magic new baby label',
            extraInput: [
                {
                    id: 'baby_name',
                    name: 'Baby name',
                    type: 'text',
                    placeHolder: 'Ex. Ezequiel',
                    translationKey: 'magic message create magic new baby title',
                    translationPlaceHolder: 'magic message create magic new baby place holder',
                },
            ]
        },
        {
            id: 'holiday',
            name: '📆  Holiday',
            translationKey: 'magic message create magic holiday label',
            extraInput: [
                {
                    id: 'holiday',
                    name: 'Holiday name',
                    type: 'text',
                    placeHolder: 'Ex. Christmas',
                    translationKey: 'magic message create magic holiday title',
                    translationPlaceHolder: 'magic message create magic holiday place holder',
                },
            ]
        },
        {
            id: 'graduation',
            name: '🎓  Graduation',
            translationKey: 'magic message create magic graduation label',
            extraInput: [
                {
                    id: 'diploma',
                    name: 'Diploma',
                    type: 'text',
                    placeHolder: 'Ex. Engineer',
                    translationKey: 'magic message create magic diploma title',
                    translationPlaceHolder: 'magic message create magic diploma place holder',
                },
            ]
        },
        {
            id: 'sorry',
            name: '🙏  Sorry',
            translationKey: 'magic message create magic sorry label',
            extraInput: [
                {
                    id: 'reason',
                    name: 'Reason',
                    type: 'text',
                    placeHolder: 'Ex. Forgot to return the book',
                    translationKey: 'magic message create magic reason title',
                    translationPlaceHolder: 'magic message create magic sorry place holder',
                },
            ]
        },
        {
            id: 'anniversary', name: '💑 Anniversary',
            translationKey: 'magic message create magic anniversary label',
        },
        {
            id: 'congrats',
            name: '🌟  Congratulations',
            translationKey: 'magic message create magic congrats label',
            extraInput: [
                {
                    id: 'reason',
                    name: 'Reason',
                    type: 'text',
                    placeHolder: 'Ex. Years married',
                    translationKey: 'magic message create magic reason title',
                    translationPlaceHolder: 'magic message create magic congrats place holder',
                },
            ]
        },
        {
            id: 'love',
            name: '❤️  Love letter',
            translationKey: 'magic message create magic love label',
            extraInput: [
                {
                    id: 'reason',
                    name: 'Reason',
                    type: 'text',
                    placeHolder: 'Ex. Your smile makes me brush',
                    translationKey: 'magic message create magic reason title',
                    translationPlaceHolder: 'magic message create magic love place holder',
                },
            ]
        },
        {
            id: 'new_job',
            name: '💼  New Job',
            translationKey: 'magic message create magic new job label',
            extraInput: [
                {
                    id: 'job_title',
                    name: 'Job Title',
                    type: 'text',
                    placeHolder: 'Ex. Layer',
                    translationKey: 'magic message create magic new job title',
                    translationPlaceHolder: 'magic message create magic new job place holder',
                },
            ]
        },
        {
            id: 'get_well',
            name: '🌻  Get Well',
            translationKey: 'magic message create magic get well label',
            extraInput: [
                {
                    id: 'reason',
                    name: 'Reason',
                    type: 'text',
                    placeHolder: 'Ex. Got a cold',
                    translationKey: 'magic message create magic reason title',
                    translationPlaceHolder: 'magic message create magic get better place holder',
                },
            ]
        },
        {
            id: 'joke',
            name: '🤣️  Joke',
            translationKey: 'magic message create magic joke label',
            extraInput: [
                {
                    id: 'joke_type',
                    name: 'Type',
                    type: 'text',
                    placeHolder: 'Ex. Knock knock/Daddy',
                    translationKey: 'magic message create magic joke title',
                    translationPlaceHolder: 'magic message create magic joke place holder',
                },
            ]
        },
        {
            id: 'friendship',
            name: '🤗  Friendship',
            translationKey: 'magic message create magic friendship label',
            extraInput: [
                {
                    id: 'reason',
                    name: 'Reason',
                    type: 'text',
                    placeHolder: 'Ex. Being awesome',
                    translationKey: 'magic message create magic reason title',
                    translationPlaceHolder: 'magic message create magic friendship place holder',
                },
            ]
        },
        {
            id: 'other', name: '➡️  Other',
            translationKey: 'magic message create magic other label',

            extraInput: [
                {
                    id: 'reason',
                    name: 'Reason',
                    type: 'text',
                    placeHolder: 'Ex. Party invitation',
                    translationKey: 'magic message create magic reason title',
                    translationPlaceHolder: 'magic message create magic other place holder',
                },
            ]
        });

    return items;
}

const isWeekBeforeOrAfter = (holidayDate, daysBefore, daysAfter) => {
    const timeBefore = new Date(holidayDate);
    timeBefore.setDate(holidayDate.getDate() - daysBefore);
    const timeAfter = new Date(holidayDate);
    timeAfter.setDate(holidayDate.getDate() + daysAfter);
    const today = new Date();
    return today >= timeBefore && today <= timeAfter;
};


export {magicTextType};
