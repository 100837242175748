import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {QueryClientProvider} from "@tanstack/react-query";
import {queryClient} from "./util/http";
import './i18n';
import {I18nextProvider} from "react-i18next";
import i18n from 'i18next';

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <I18nextProvider i18n={i18n}>
                <App sty/>
            </I18nextProvider>
        </QueryClientProvider>
    </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
