import styles from "./GiftEnd.module.css";
import React, {useEffect, useRef} from "react";
import {NavLink} from "react-router-dom";
import {PUT} from "../../util/http";
import ReactPlayer from "react-player";
import {Breathing, Image} from "react-shimmer";
import {useTranslation} from "react-i18next";
import ScrollToTop from "../UI/ScrollToTop";

const GiftEnd = (props) => {

    const giftId = props.gift['id'];
    const title = props.gift['title'];
    const message = props.gift['message'];
    const image = props.gift['displayImage'];
    const youtube = props.gift['youtube'];

    const {t, i18n} = useTranslation();


    const dataFetch = useRef(false);
    useEffect(() => {
        // start:: prevent executing useEffect twice in DEV
        if (dataFetch.current)
            return
        dataFetch.current = true
        PUT('gifts/open/' + giftId);
    }, [])


    return <ScrollToTop>
        <div className={styles.container}>

            <div className={styles.createButtonContainer}>
                <NavLink className={styles.createButton} to="/create">
                    {t('create new txt.gift')}
                </NavLink>
            </div>

            <div className={styles.containerInner}>
                <div className={styles.title}>{title}</div>
                {/*MEDIA*/}
                {image && <div>
                    <Image alt={'Image'} src={image}
                           NativeImgProps={{className: styles.imageContainer}}
                           fallback={<Breathing width={200} height={200} className={styles.imageContainer}
                                                NativeImgProps={{className: styles.imageContainer}}/>}
                    /></div>}
                {youtube && <div className={styles.youtubeContainer}>
                    <ReactPlayer url={youtube} playing={true} controls={true}/>
                </div>}

                {/*MESSAGE*/}
                <div className={styles.message}>{message}</div>

            </div>
        </div>
    </ScrollToTop>
}

export default GiftEnd;
