import styles from './MessageForm.module.css';
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import GradientButton from "../../UI/designs/GradientButton";
import {getEmojiBasedOnLength} from '../../../util/utils';
import Modal from "../../UI/Modal";
import MagicMessageGenerate from "../templates/MagicMessageGenerate";
import {POST} from "../../../util/http";
import userStore from "../../../store/userStore";
import {sendEvent} from "../../../util/firebase-analytics";

const MessageForm = (props) => {

    const {t, i18n} = useTranslation();
    // const [charsCount, setCharsCount] = useState(5000);
    const [showMagicText, setShowMagicText] = useState(false);

    const message = (props.gift && props.gift.message) ? props.gift.message : '';
    const charsCount = 5000 - message.length;

    const generateMessage = async (body) => {
        setShowMagicText(false);
        props.onUpdate('isLoading', true);
        try {
            const res = await POST('users/magicMessage', {...body, lang: i18n.language});
            if (res.status === 200) {
                props.onUpdate('message', JSON.parse(res.content).message);
                userStore.value.user.magicTexts -= 1;
            }
            sendEvent('create_start', {magic_text_count: 1})
        } catch (e) {}
        props.onUpdate('isLoading', false);
    }


    return <div className={styles.container}>

        <form className={styles.form} onSubmit={(e) => {
            e.preventDefault()
        }}>
                {/*MAGIC GENERATOR*/}
                <div className={styles.titleContainer}>
                    <label className={styles.title}>{t('create message title Customize')}</label>
                    <GradientButton text={t('create Magic Message Button')} onClick={()=>{setShowMagicText(true)}}/>
                </div>

                <textarea className={(props.gift.error && props.gift.error !=='') ? styles.textAreaError : styles.textArea}
                          value={message}
                          placeholder={t('create message title Customize placeholder')}
                          maxLength={5000}
                          onChange={(element) => {
                              props.onUpdate('message', element.target.value);
                              // setCharsCount(5000 - element.target.value.length);
                          }}
                />
                <div className={styles.charsRemainContainer}>
                    <div className={styles.charsEmoji}>{getEmojiBasedOnLength(message.length)}</div>
                    <div className={styles.charsRemain}>{charsCount.toLocaleString()}</div>
                </div>

        </form>

        {/*MAGIC TEXT GENERATOR*/}
        <Modal isShowing={showMagicText} onBackClick={() => {setShowMagicText(false)}}>
            <MagicMessageGenerate onGenerated={generateMessage}/>
        </Modal>
    </div>;
}

export default MessageForm;
