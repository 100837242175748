import styles from "./ShareGift.module.css";
import {useEffect, useState} from "react";
import ShareSocial from "./ShareSocial";
import {useTranslation} from "react-i18next";
import GradientButton from "../../UI/designs/GradientButton";
import {motion} from "framer-motion";
import {sendEvent} from "../../../util/firebase-analytics";

const ShareGift = (props) => {

    useEffect(()=>{
        sendEvent('create_end', {gift_created: 1})
    }, [])

    const {t, i18n} = useTranslation();

    const url = props.url;
    const [textCopied, setTextCopied] = useState(false);

    const handleCopyClick = () => {
        if (textCopied) return;
        navigator.clipboard.writeText(url);
        setTextCopied(true);
        setTimeout(()=>{
            setTextCopied(false);
        }, 2000);
    }



    return <div className={styles.container}>
        <div className={styles.containerInner}>
            <div className={styles.form}>

                {/*URL*/}
                <label className={styles.label}>{t('create step 3 title')}</label>

                <div className={styles.urlContainer}>
                    <div className={styles.url}>{url}</div>
                    <button className={styles.copyButton} onClick={handleCopyClick}>
                        {!textCopied &&
                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" viewBox="0 0 22 23"
                                 fill="none">
                                <path d="M17.4883 6H7.94922C6.59655 6 5.5 7.09655 5.5 8.44922V17.9883C5.5 19.3409 6.59655 20.4375 7.94922 20.4375H17.4883C18.8409 20.4375 19.9375 19.3409 19.9375 17.9883V8.44922C19.9375 7.09655 18.8409 6 17.4883 6Z" stroke="white" stroke-width="2" stroke-linejoin="round"/>
                                <path d="M16.4785 6L16.5 4.96875C16.4982 4.33113 16.2441 3.72014 15.7932 3.26928C15.3424 2.81841 14.7314 2.56431 14.0938 2.5625H4.8125C4.08382 2.56465 3.38559 2.85508 2.87034 3.37034C2.35508 3.88559 2.06465 4.58382 2.0625 5.3125V14.5938C2.06431 15.2314 2.31841 15.8424 2.76928 16.2932C3.22014 16.7441 3.83113 16.9982 4.46875 17H5.5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        }
                        {textCopied &&
                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 17 16" fill="none">
                                <path d="M15.1649 3.11702L7.10803 11.3241L4.16711 8.29716C4.01394 8.17234 3.76886 8.17234 3.64632 8.29716L2.75792 9.20213C2.63538 9.32695 2.63538 9.5766 2.75792 9.73262L6.86295 13.883C7.01612 14.039 7.23057 14.039 7.38374 13.883L16.5741 4.52128C16.6966 4.39645 16.6966 4.14681 16.5741 3.99078L15.6857 3.11702C15.5632 2.96099 15.3181 2.96099 15.1649 3.11702Z" fill="white"/>
                            </svg>
                        }
                        {t('create step 3 copy button')}
                    </button>
                </div>

                {/*SHARE IN ON*/}
                <label className={styles.label}>{t('create step 3 share title')}</label>
                <div className={styles.socialButtonsContainer}>
                    <ShareSocial url={url}/>
                </div>

                <motion.div
                    variants={{
                        hidden: {opacity: 0},
                        visible: {opacity: 1},
                    }}
                    transition={{duration: .3, delay: 3}}
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                    className={styles.createButtonContainer}
                >
                    <GradientButton
                        className={styles.createButton}
                        text={t('create new txt.gift')}
                        onClick={props.onCreateNewClick}
                    />
                </motion.div>

            </div>
        </div>
    </div>;
}
export default ShareGift;
