import {PUT} from "../../../util/http";
import React, {useEffect, useState} from "react";
import userStore from "../../../store/userStore";
import styles from "./MyGifts.module.css";
import {NavLink, useNavigate} from "react-router-dom";
import {ClipLoader} from "react-spinners";
import {useTranslation} from "react-i18next";
import youtubeIcon from '../../../assets/images/youtube.png';
import copy from '../../../assets/images/copy.svg';
import share from '../../../assets/images/share.svg';
import edit from '../../../assets/images/edit.svg';
import remove from '../../../assets/images/delete.svg';
import Selector from "awesome-selector";
import {selectorStyle} from "../../../util/utils";
import loadingStore from "../../../store/loadingStore";
import GradientButton from "../../UI/designs/GradientButton";
import Modal from "../../UI/Modal";
import ShareSocial from "../../create/steps/ShareSocial";
import {Breathing, Image, Shimmer} from "react-shimmer";
import {fetchUserGifts} from "../../../util/backend-utils";

const selectorDefaultItemOptions = [
    {id: 'copy', name: 'profile gift copy', iconSrc: copy, iconSize: '20px',},
    {id: 'share', name: 'profile gift share', iconSrc: share, iconSize: '20px',},
    {id: 'edit', name: 'profile gift edit', iconSrc: edit, iconSize: '20px',},
    {id: 'delete', name: 'profile gift delete', iconSrc: remove, iconSize: '20px',},
]


const MyGifts = (props) => {


    useEffect(()=>{
        if (!loadingStore.value.userGifts && userStore.value.user)
        {
            fetchUserGifts();
        }
    }, [userStore.value.user]);


    const [itemShareURL, setItemShareURL] = useState(null);
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();

    const onItemClickOptions = [
        {id: 'copy', name: t('profile gift copy'), iconSrc: copy, iconSize: '20px',},
        {id: 'share', name: t('profile gift share'), iconSrc: share, iconSize: '20px',},
        {id: 'edit', name: t('profile gift edit'), iconSrc: edit, iconSize: '20px',},
        {id: 'delete', name: t('profile gift delete'), iconSrc: remove, iconSize: '20px',},
    ]
    const itemDeleteOptions = [
        {id: 'delete', name: t('profile gift delete ok')},
        {id: 'cancel', name: t('profile gift delete cancel')}
    ]

    const onItemClick = (giftId, index) => {
        new Selector({
            theme: 'light',
            title: '#' + (index + 1),
            options: onItemClickOptions,
            style: selectorStyle,
            onSelect: (id, name) => {
                if (id === 'edit') {
                    navigate("/create/" + giftId);
                }
                if (id === 'share') {
                    // Share URL
                    const baseUrl = process.env.REACT_APP_ENV === 'prod' ? 'https://txt.gift/g/' : 'http://localhost:3000/g/';
                    setItemShareURL(baseUrl + giftId);
                }
                if (id === 'copy') {
                    // Share URL
                    const baseUrl = process.env.REACT_APP_ENV === 'prod' ? 'https://txt.gift/g/' : 'http://localhost:3000/g/';
                    navigator.clipboard.writeText(baseUrl + giftId);
                }
                if (id === 'delete') {
                    new Selector({
                        theme: 'light',
                        title: t('profile gift delete title'),
                        options: itemDeleteOptions,
                        style: selectorStyle,
                        onSelect: async (id, name) => {
                            if (id === 'delete') {
                                loadingStore.value = {...loadingStore.value, userGifts: true};
                                await deleteGift(giftId);
                                loadingStore.value = {...loadingStore.value, userGifts: false};
                            }
                        }
                    });
                }
            }
        });
    }


    const deleteGift = async (giftId) => {
        const deleteRes = await PUT('gifts/delete/' + giftId);
        if (deleteRes.status === 200) {
            // console.log('delete-Gifts',userStore.value.gifts);
            let newArr;
            if (userStore.value.user?.gifts && userStore.value.user?.gifts.length <= 1)
                newArr = [];
            else
                newArr = userStore.value.user.gifts.filter((item) => item.id !== giftId);
            userStore.value.user.gifts = newArr;
        }
    }

    let content = <></>;
    if (loadingStore.value.userGifts) {
        // Show Loading
        content = <div className={styles.loadingContainer}><ClipLoader color="#7155F6" loading={true} size={25}
                                                                       aria-label="Loading Spinner"
                                                                       data-testid="loader"/></div>;
    } else {
        const userGifts = userStore.value.user?.gifts;
        if (!userGifts || userGifts.length === 0) {
            // User Has No Gifts
            content = <div className={styles.createButtonContainer}>
                <GradientButton text={t('create new txt.gift')} onClick={() => {
                    navigate('/create')
                }}/>
            </div>;
        } else {
            // Display User Gifts
            content = <div className={styles.allGifts}>
                {userGifts.map((item, index) => {
                    // return <div key={index}></div>;
                    return <div key={item.id} className={styles.giftContainer} onClick={() => {
                        onItemClick(item.id, index)
                    }}>
                        <div className={styles.gift}>
                            <div className={styles.giftLink}>
                                <div className={styles.giftMessage}>#{index + 1}</div>

                                {/*IMAGE*/}
                                {(!item.youtube && item.displayImage) &&
                                    <Image
                                        className={styles.selectedImage}
                                        alt='Selected Image'
                                        src={item.displayImage}
                                        NativeImgProps={{className: styles.giftImage}}
                                        fallback={<Breathing width={200} height={200} NativeImgProps={{className: styles.giftImage}}/>}
                                    />
                                }
                                {/*VIDEO*/}
                                {item.youtube && <img src={youtubeIcon} className={styles.giftImage} alt="Youtube"/>}
                                {/*MESSAGE*/}
                                <div className={styles.giftMessage}>{item.message.length > 50 ? item.message.slice(0, 40) + '...' : item.message}</div>
                            </div>
                        </div>
                    </div>
                })}
            </div>;
        }
    }

    return <div className={styles.container}>
        {/*MY GIFTS*/}
        <div className={styles.seperator}/>
        <div className={styles.textMyGifts}>{t('profile my gifts title')}</div>
        {content}


        {/*SHARE URL MODAL*/}
        <Modal isShowing={itemShareURL !== null} onBackClick={()=>setItemShareURL(null)}>
            <div className={styles.shareSocial}>
                <div className={styles.shareSocialTitle}>{t('profile share gift title')}</div>
                <ShareSocial url={itemShareURL}/>
            </div>
        </Modal>
    </div>
}
export default MyGifts;
