import styles from './RoundStep.module.css';

const RoundStep = (props) => {

    const state = props.state;
    const text = props.text;
    const title = props.title;

    return <div className={styles.container}>
        <div className={state === 'active' || state === 'done' ? styles.circleBoarderActive : styles.circleBoarder}>
            <div className={ state === 'active' || state === 'done' ? styles.circleActive : styles.circle}>
                {state !== 'done' && <div className={styles.text}>{text}</div>}
                {state === 'done' && <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 17 16" fill="none">
                    <path d="M15.1649 3.11702L7.10803 11.3241L4.16711 8.29716C4.01394 8.17234 3.76886 8.17234 3.64632 8.29716L2.75792 9.20213C2.63538 9.32695 2.63538 9.5766 2.75792 9.73262L6.86295 13.883C7.01612 14.039 7.23057 14.039 7.38374 13.883L16.5741 4.52128C16.6966 4.39645 16.6966 4.14681 16.5741 3.99078L15.6857 3.11702C15.5632 2.96099 15.3181 2.96099 15.1649 3.11702Z" fill="white"/>
                </svg>}
            </div>

        </div>
        <div className={state === 'active' ? styles.titleActive : styles.title}>{title}</div>
    </div>;
}

export default RoundStep;
